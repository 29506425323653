import React from 'react';
import Navbar from '../components/Navbar';
import backgroundImage from "../assets/backgroundMain.jpg";


function Pdf() {

  const containerStyle = {
    margin: "0 0 0 0",
    padding: "0 0 0 0",
    backgroundImage: `url(${backgroundImage})`, // Set the background image
    backgroundSize: "cover", // Adjust background sizing
    backgroundRepeat: "no-repeat", // Prevent repeating of background image
    backgroundColor: "#f2f2f2",
    width: "100vw",
    height: "100%",
    // Add other styles as needed
  };

  const onButtonClick = () => {
    const pdfUrl = '';
    const link = document.createElement('a');
    link.href = pdfUrl;
    link.download = '.pdf';
    document.body.appendChild(link);
    link.click();
    document.body.remove(link);
  };


  return (
    <div id="" style={containerStyle} className="w-full font-custom">
      <Navbar/>
      
      <div>
        <script>
            window.open("assets/Starlink_wolf_sheep_clothing.pdf", "_blank");
        </script>
      </div>
    </div>
  );
}

export default Pdf;
