import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import Navbar from '../components/Navbar';
import backgroundImage from "../assets/backgroundMain.jpg";
import { useMediaQuery } from "react-responsive";
import { BarLoader } from "react-spinners";
import scrollButtonImage from "../assets/scrollToTopArrow.svg"



function LatestArticles() {
    const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the mobile breakpoint
    const isLaptop = useMediaQuery({ minWidth: 780 });

    const [coutries, setCountries] = useState([]);
    const [articles, setArticles] = useState([]);
    const [allFlags, setAllFlags] = useState([]);
    const [allArticles, setAllArticles] = useState([]);

    const [allTypeArticlesList, setAllTypeArticlesList] = useState([]);
    const [posArticles, setPosArticles] = useState([]);
    const [neuArticles, setNeuArticles] = useState([]);
    const [negArticles, setNegArticles] = useState([]);

    const [isLoading, setIsLoading] = useState(false);
    const [articlesNumber, setArticlesNumber] = useState(20);
    const [countrySelected, setCountrySelected] = useState("World");
    const [tagSelected, setTagSelected] = useState("All");

    const [isPositive, setIsPositive] = useState(false);
    const [isNeutral, setIsNeutral] = useState(false);
    const [isNegative, setIsNegative] = useState(false);

    const [selectedOption, setSelectedOption] = useState('Select a country');

    const [isVisible, setIsVisible] = useState(false);

    const handleScroll = () => {
      if (window.scrollY > 100) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
          window.removeEventListener('scroll', handleScroll);
        };
      }, []);
    
    useEffect(() => {
        const fetchAllArticles = async () => {
          setIsLoading(true);
          try {
            const requestData = {
                country: countrySelected,
            };
    
            const response = await fetch(
                "https://api.kutniti.watch/api/article/getLatestArticlesByCountry",
                //"http://localhost:8000/api/article/getLatestArticlesByCountry",
                {
                    method: "POST",
                    headers: {
                    "Content-Type": "application/json",
                    },
                    body: JSON.stringify(requestData),
                }
            );
    
            if (response.ok) {
              const getData = await response.json();
              setIsLoading(false);
              getData.sort((a, b) => {
                const dateA = new Date(a.pubDate);
                const dateB = new Date(b.pubDate);
                return dateB - dateA;
              });
              const uniqueArticles = [];
    
              getData.forEach((item) => {
                    const dateString = item.pubDate;
                    const date = new Date(dateString);
        
                    const day = date.getDate().toString().padStart(2, '0');
                    const month = (date.getMonth() + 1).toString().padStart(2, '0');
                    const year = date.getFullYear();
        
                    const parser = new DOMParser();
                    const doc = parser.parseFromString(item.content, 'text/html');
        
                    const imgElement = doc.querySelector('img');
                    let image = null;
                    if (imgElement) {
                    image = imgElement.getAttribute('src');
                    }

                    const formattedDate = `${day}/${month}/${year}`;
                    let article = {
                        title: item.title,
                        type: item.type,
                        pubDate: formattedDate,
                        author: item.author,
                        image: image,
                        link: item.link,
                        country_id: item.country_id,
                        tags: item.tags,
                    }
                  uniqueArticles.push(article);
              });

            setAllTypeArticlesList(uniqueArticles.slice(0,100));
            setPosArticles(uniqueArticles.filter((item) => item.type === "Positive").slice(0,100));
            setNeuArticles(uniqueArticles.filter((item) => item.type === "Neutral").slice(0,100));
            setNegArticles(uniqueArticles.filter((item) => item.type === "Negative").slice(0,100));

            setAllArticles(uniqueArticles.slice(0,100));
            setArticles(uniqueArticles.slice(0,articlesNumber));
            } else {
              console.error("API call failed");
            }
          } catch (error) {
            setIsLoading(false);
            console.error("Error:", error);
          }
        };
        fetchAllArticles();
      }, []); // Empty dependency array means this effect runs once after the component mounts

      useEffect(() => {
        const fetchAllCountries = async () => {
            try {
                const response = await fetch(
                "https://api.kutniti.watch/api/allRssLinks",
                {
                    method: "GET",
                }
                );
        
                if (response.ok) {
                const getData = await response.json();
                const uniqueCoutries = [];
        
                getData.forEach((item) => {
                    let country = {
                        country_id: item.country_id,
                        country: item.country,
                    }
                    uniqueCoutries.push(country);
                });
                setCountries(uniqueCoutries);
                } else {
                console.error("API call failed");
                }
            } catch (error) {
                setIsLoading(false);
                console.error("Error:", error);
            }
        };
    
        fetchAllCountries();

        const fetchAllFlags = async () => {
            try {
              const response = await fetch(
                "https://api.kutniti.watch/api/country/getallCountryArticleNumber",
                {
                  method: "GET",
                }
              );
      
              if (response.ok) {
                const getData = await response.json();
      
                const uniqueCountries = [];
                const countryNames = {};
      
                getData.forEach((item) => {
                  const { countryName, flagLogo } = item;
                  if (!countryNames[countryName]) {
                    countryNames[countryName] = true;
                    uniqueCountries.push({ countryName, flagLogo });
                  }
                });
                uniqueCountries.sort((a,b) => {return a.countryName.localeCompare(b.countryName)})
                uniqueCountries.unshift({countryName: "World", flagLogo: "https://img.freepik.com/free-vector/illustration-global-icon_53876-9267.jpg?size=626&ext=jpg&ga=GA1.1.1546980028.1702252800&semt=ais"})
      
                setAllFlags(uniqueCountries);
              } else {
                console.error("API call failed");
              }
            } catch (error) {
              console.error("Error:", error);
            }
        };

        fetchAllFlags();
    }, []);

    const tags = [
        "All",
        "Society",
        "Geopolitics",
        "Politics",
        "Environment",
        "Economy/Business",
        "Culture",
        //"Sports",
        //"Science",
        //"Tourism",
    ]

      const handleLoadMore = () => {
        if (articlesNumber < allArticles.length){
          setArticlesNumber(articlesNumber+20);
          setArticles(allArticles.slice(0,articlesNumber+20))
        }
      };

      const allTypeArticles = () => {
        setIsPositive(false);
        setIsNeutral(false);
        setIsNegative(false);
        
        setAllArticles(allTypeArticlesList);
        setArticlesNumber(20);
        setArticles(allTypeArticlesList.slice(0,20));
      };
    
      const positiveArticles = () => {
        setIsPositive(!isPositive);
        setIsNeutral(false);
        setIsNegative(false);

        if (!isPositive){
            setAllArticles(posArticles);
            setArticlesNumber(20);
            setArticles(posArticles.slice(0,20));
        }
        else{
            setAllArticles(allTypeArticlesList);
            setArticlesNumber(20);
            setArticles(allTypeArticlesList.slice(0,20));
        }
      };
    
      const neutralArticles = () => {
        setIsPositive(false);
        setIsNeutral(!isNeutral);
        setIsNegative(false);

        if (!isNeutral){
            setAllArticles(neuArticles);
            setArticlesNumber(20);
            setArticles(neuArticles.slice(0,20));
        }
        else{
            setAllArticles(allTypeArticlesList);
            setArticlesNumber(20);
            setArticles(allTypeArticlesList.slice(0,20));
        }
      };
    
      const negativeArticles = () => {
        setIsPositive(false);
        setIsNeutral(false);
        setIsNegative(!isNegative);

        if (!isNegative){
            setAllArticles(negArticles);
            setArticlesNumber(20);
            setArticles(negArticles.slice(0,20));
        }
        else{
            setAllArticles(allTypeArticlesList);
            setArticlesNumber(20);
            setArticles(allTypeArticlesList.slice(0,20));
        }
      };

      const latestArticles = async (country) => {
        setIsLoading(true);
        try {
          const requestData = {
              country: country,
          };
  
          const response = await fetch(
              "https://api.kutniti.watch/api/article/getLatestArticlesByCountry",
              //"http://localhost:8000/api/article/getLatestArticlesByCountry",
              {
                  method: "POST",
                  headers: {
                  "Content-Type": "application/json",
                  },
                  body: JSON.stringify(requestData),
              }
          );
  
          if (response.ok) {
            const getData = await response.json();
            setIsLoading(false);
            getData.sort((a, b) => {
              const dateA = new Date(a.pubDate);
              const dateB = new Date(b.pubDate);
              return dateB - dateA;
            });
            const uniqueArticles = [];
  
            getData.forEach((item) => {
                  const dateString = item.pubDate;
                  const date = new Date(dateString);
      
                  const day = date.getDate().toString().padStart(2, '0');
                  const month = (date.getMonth() + 1).toString().padStart(2, '0');
                  const year = date.getFullYear();
      
                  const parser = new DOMParser();
                  const doc = parser.parseFromString(item.content, 'text/html');
      
                  const imgElement = doc.querySelector('img');
                  let image = null;
                  if (imgElement) {
                  image = imgElement.getAttribute('src');
                  }

                  const formattedDate = `${day}/${month}/${year}`;
                  let article = {
                      title: item.title,
                      type: item.type,
                      pubDate: formattedDate,
                      author: item.author,
                      image: image,
                      link: item.link,
                      country_id: item.country_id,
                      tags: item.tags,
                  }
                uniqueArticles.push(article);
            });

          setAllTypeArticlesList(uniqueArticles.slice(0,100));
          setPosArticles(uniqueArticles.filter((item) => item.type === "Positive").slice(0,100));
          setNeuArticles(uniqueArticles.filter((item) => item.type === "Neutral").slice(0,100));
          setNegArticles(uniqueArticles.filter((item) => item.type === "Negative").slice(0,100));

          setAllArticles(uniqueArticles.slice(0,100));
          setArticles(uniqueArticles.slice(0,articlesNumber));
          } else {
            console.error("API call failed");
          }
        } catch (error) {
          setIsLoading(false);
          console.error("Error:", error);
        }
      };

      const scrollToTop = () => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth' 
        });
      };

      const handleTags = (item) => {
        setTagSelected(item);
        setArticlesNumber(100);
        setArticles(allArticles.slice(0,100))
      }

      const handleChange = (item) => {
        setIsPositive(false);
        setIsNeutral(false);
        setIsNegative(false);
        setCountrySelected(item.countryName);
        latestArticles(item.countryName);
      };

      const filterArticles = (article) => {
        if (countrySelected !== "World"){
            let country = coutries.find((country) => country.country_id == article.country_id);
            if (countrySelected === country.country){
                if (tagSelected === "All"){
                    return article;
                }
                let tags = article.tags.split(',');
                if (tags.find((tag) => tag.replace(/[^a-zA-Z0-9 / ]/g, '').toLowerCase() == tagSelected.toLowerCase())){
                    return article
                }
                //else{console.log(tags.find((tag) => tag.replace(/[^a-zA-Z0-9 / ]/g, '').toLowerCase() !== tagSelected.toLowerCase()))}
            }
        }
        else{
            if (tagSelected === "All"){
                return article;
            }
            let tags = article.tags.split(',');
            if (tags.find((tag) => tag.replace(/[^a-zA-Z0-9 / ]/g, '').toLowerCase() == tagSelected.toLowerCase())){
                return article
            }
        }
      }

      const onOptionChangeHandlerCountry = (event) => {
        setIsPositive(false);
        setIsNeutral(false);
        setIsNegative(false);
        setCountrySelected(event.target.value);
        latestArticles(event.target.value);
    };

    const onOptionChangeHandlerTags = (event) => {
        if (event.target.value === "All categories") {
            handleTags("All");
        }
        else {
            handleTags(event.target.value);
        }
    }
    
    const containerStyle = {
        margin: "0 0 0 0",
        padding: "0 0 0 0",
        backgroundImage: `url(${backgroundImage})`, // Set the background image
        backgroundSize: "cover", // Adjust background sizing
        backgroundRepeat: "no-repeat", // Prevent repeating of background image
        backgroundColor: "#f2f2f2",
        width: "100%",
        height: "100%",
    // Add other styles as needed
    };

    // console.log(allFlags)
    // console.log(coutries)

    return (
    <div id="" style={containerStyle} className="w-full font-custom">
        <Navbar/>
        {isLaptop && (
        <div className="min-h-screen flex px-7 p-2">
            <div className="w-full">

                <h1 className="font-bold text-3xl p-5 invisible">
                    Providing Free spacing
                </h1>

                <div className="flex min-h-[85%] bg-white bg-opacity-30 backdrop-blur-[3px] rounded-2xl shadow-2xl mt-4 px-5 pb-2 pt-5 border border-gray-300">
                    <div className=" p-2 w-full">
                        <div className="flex justify-between my-1 w-full mb-5">
                            <h2 className="text-[25px] font-bold">
                                Latest Articles about India from {countrySelected === "World" ? "the World" : countrySelected}
                            </h2>
                            <div className="w-[50%] flex justify-between">
                                <div className="flex w-[22%]">
                                    <div
                                        onClick={allTypeArticles}
                                        className={`flex w-full text-center cursor-pointer p-2 py-1 font-semibold border-2 rounded-lg border-gray-400 bg-gray-400 hover:bg-opacity-30 ${!isPositive && !isNeutral && !isNegative  ? " border-gray-800 bg-opacity-30" : "bg-opacity-0"}`}
                                    >
                                        <div className="self-center w-full text-center">All</div>
                                    </div>
                                </div>
                                <div className="font-custom h-8 bg-gray-700 w-px mx-2 self-center "></div>
                                <div className="flex w-[22%]">
                                    <div
                                        onClick={negativeArticles}
                                        className={`flex w-full text-center cursor-pointer p-2 py-1 font-semibold rounded-lg border-2 border-custom-red bg-custom-red hover:bg-opacity-30 ${isNegative ? " bg-opacity-30" : "bg-opacity-0"}`}
                                    >
                                        <div className="self-center w-full text-center">Negative</div>
                                    </div>
                                </div>
                                <div className="flex w-[22%]">
                                    <div
                                        onClick={neutralArticles}
                                        className={`flex w-full text-center cursor-pointer p-2 py-1 font-semibold border-2 rounded-lg border-custom-yellow2 bg-custom-yellow2 hover:bg-opacity-30 ${isNeutral ? " bg-opacity-30" : "bg-opacity-0"}`}
                                    >
                                        <div className="self-center w-full text-center">Neutral</div>
                                    </div>
                                </div>
                                <div className="flex w-[22%]">
                                    <div
                                        onClick={positiveArticles}
                                        className={`flex w-full text-center cursor-pointer p-2 py-1 font-semibold border-2 rounded-lg border-custom-green bg-custom-green hover:bg-opacity-30 ${isPositive ? " bg-opacity-30" : "bg-opacity-0"}`}
                                    >
                                        <div className="self-center w-full text-center">Positive</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="flex justify-center bg-gray-200 rounded-lg p-2">
                            <div className="w-full space-y-2">
                                <div className="flex w-full justify-stretch space-x-2">
                                    {allFlags.map((item) => (
                                        <div className="w-full flex">
                                            <button className={countrySelected === item.countryName ? "relative w-full group rounded-lg" : "relative w-full group rounded-lg "} onClick={() => handleChange(item)}>
                                                <img
                                                src={item.flagLogo}
                                                alt="flag"
                                                className="w-full h-10 rounded-lg object-cover"
                                                />
                                                <div className={countrySelected === item.countryName ? "absolute flex justify-center top-0 w-full h-10 rounded-lg bg-black opacity-100 bg-opacity-40 text-white backdrop-blur-[2px] border-gray-800 border-2" : "absolute flex justify-center top-0 w-full h-10 rounded-lg bg-black opacity-0 text-white backdrop-blur-[2px] hover:bg-opacity-40 hover:opacity-100"}>
                                                    <div className="self-center font-custom font-bold">{item.countryName}</div>
                                                </div>
                                            </button>
                                            <div className={item.countryName === "World" ? "font-custom h-8 bg-gray-700 w-px ml-2 self-center " : "hidden"}></div>
                                        </div>
                                    ))}
                                </div>
                                <div className="w-full flex">
                                    <div className="flex w-full justify-stretch space-x-2">
                                        {tags.map((item) => (
                                            <div className="flex w-full">
                                                <div className="flex w-full bg-white rounded-lg">
                                                    <div
                                                        onClick={() => handleTags(item)}
                                                        className={`flex w-full cursor-pointer p-2 py-1 font-semibold border-2 rounded-lg border-gray-400 bg-gray-400 ${item === tagSelected  ? " border-gray-800 bg-opacity-30" : "hover:bg-opacity-30 bg-opacity-0"}`}
                                                    >
                                                        <div className="self-center w-full text-center">{item}</div>
                                                    </div>
                                                </div>
                                                <div className={item === "All" ? "font-custom h-7 bg-gray-700 w-px ml-2 self-center " : "hidden"}></div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <hr className="border-t-1 border-black w-full my-3" />

                        {isLoading && (
                        <div className="h-1/2 flex justify-center">
                            <div className=" self-center spinner-container mt-10">
                            <BarLoader color="black" width={200} />
                            </div>
                        </div>
                        )}
                        {!isLoading && (
                        <div className="h-[65%] space-y-3">
                            {articles.filter((article) => filterArticles(article)).length > 0 ?
                            (<div className="grid grid-cols-5 gap-6 flexy">
                            {articles.filter((article) => filterArticles(article)).map((item) => (
                                <div className="">
                                    <div className="h-full">
                                        <a href={item.link} target="_blank">
                                            <div
                                            className={`shadow-md border-4 p-1 pb-1 hover:bg-opacity-30 bg-opacity-5 backdrop-blur-[3px] rounded-xl drop-shadow-lg w-full h-full  ${
                                                item.type === "Negative"
                                                ? "bg-custom-red border-custom-red"
                                                : item.type === "Neutral"
                                                ? "bg-custom-yellow2 border-custom-yellow2"
                                                : "bg-custom-green border-custom-green"
                                            }`}
                                            >
                                                <div className="h-full">
                                                    <div className="w-full p-1 px-2">
                                                        {coutries.find((country) => country.country_id == item.country_id) && 
                                                        <div className="flex justify-center text-center text-black text-sm font-bold p-1 px-2 bg-white bg-opacity-70 rounded-md">
                                                            <img 
                                                            src={allFlags.find((flag) => flag.countryName == coutries.find((country) => country.country_id == item.country_id).country).flagLogo}
                                                            className="w-7 h-5 rounded-md mr-2 my-auto border-2"/>
                                                            <p className="text-center text-black text-sm font-bold">
                                                            {coutries.find((country) => country.country_id == item.country_id).country}
                                                            </p>
                                                        </div>
                                                        }
                                                    </div>

                                                    {item.image && (
                                                    <div className="w-full p-1 px-2">
                                                        <img
                                                        src={item.image}
                                                        alt="illustration of article"
                                                        className="w-full h-[100px] rounded-lg"
                                                        />
                                                    </div>
                                                    ) || (
                                                    <div className="w-full p-1 px-2">
                                                        <img
                                                        src="https://img.freepik.com/premium-vector/breaking-news-tv-banners-white-background_714603-853.jpg"
                                                        alt="illustration of article"
                                                        className="w-full h-[100px] object-cover rounded-lg"
                                                        />
                                                    </div>
                                                    )}
                                                    
                                                    <div className="w-full px-1">
                                                        <div className="float-left">
                                                            <h2 className="text-md font-bold mt-2">
                                                            {item.title}
                                                            </h2>
                                                            <p className="text-gray-600 text-sm font-bold self-center">
                                                                {item.author}
                                                            </p>
                                                            <p className="text-gray-600 text-md">{item.pubDate}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                                )) }
                                </div>)                           
                            :(
                            <div className="h-full w-full flex justify-center">
                                <div className="h-full flex justify-center bg-gray-800 w-full bg-opacity-90 text-white rounded-lg">
                                <div className="self-center">
                                    <div className="flex justify-center">
                                        <img
                                        src="https://i.pinimg.com/originals/52/4c/6c/524c6c3d7bd258cd165729ba9b28a9a2.png"
                                        alt="no data found illustration"
                                        className="h-24"
                                        />
                                    </div>
                                    <div className="flex justify-center">
                                        <div className="font-bold font-custom text-xl w-full text-center">
                                        Not Enough Data On The Last 100 Analized Articles
                                        </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                            )}
                            
                            <div className={articlesNumber < allArticles.length ? "flex justify-center" : "hidden"}>
                                <button className="bg-gray-700 bg-opacity-0 hover:bg-opacity-30 border-2 border-gray-700 rounded-lg w-1/3 my-4 font-bold text-lg" onClick={handleLoadMore}>Load More</button>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
        )}
      {isMobile && (
        <div className="min-h-screen flex px-4 p-2">
            <div className="w-full">

                <h1 className="font-bold text-2xl p-4 invisible">
                    Providing Free spacing
                </h1>

                <div className="flex h-[98%] bg-white bg-opacity-30 backdrop-blur-[3px] rounded-2xl shadow-2xl mt-4 px-2 py-2 border border-gray-300">
                    <div className=" p-2 w-full space-y-2">
                        <div className="text-lg font-bold left">
                            Latest Articles about India
                        </div>
                        <div className="w-full flex justify-between space-x-2 text-xs">
                            <div className="flex w-1/4">
                                <div
                                    onClick={allTypeArticles}
                                    className={`w-full text-center cursor-pointer py-1 font-semibold border-2 rounded-lg border-gray-400 bg-gray-400 ${!isPositive && !isNeutral && !isNegative  ? "bg-opacity-30" : "bg-opacity-0"}`}
                                >
                                    All
                                </div>
                            </div>
                            <div className="flex w-1/4">
                                <div
                                    onClick={negativeArticles}
                                    className={`w-full text-center cursor-pointer py-1 font-semibold rounded-lg border-2 border-custom-red bg-custom-red ${isNegative ? "bg-opacity-30" : "bg-opacity-0"}`}
                                >
                                    Negative
                                </div>
                            </div>
                            <div className="flex w-1/4">
                                <div
                                    onClick={neutralArticles}
                                    className={`w-full text-center cursor-pointer py-1 font-semibold border-2 rounded-lg border-custom-yellow2 bg-custom-yellow2 ${isNeutral ? "bg-opacity-30" : "bg-opacity-0"}`}
                                >
                                    Neutral
                                </div>
                            </div>
                            <div className="flex w-1/4">
                                <div
                                    onClick={positiveArticles}
                                    className={`w-full text-center cursor-pointer py-1 font-semibold border-2 rounded-lg border-custom-green bg-custom-green ${isPositive ? "bg-opacity-30" : "bg-opacity-0"}`}
                                >
                                    Positive
                                </div>
                            </div>
                        </div>
                        <div className="flex justify-stretch w-full space-x-2">
                            <select onChange={onOptionChangeHandlerCountry} className="flex w-full bg-gray-200 rounded-lg p-1 font-bold">
                                {allFlags.map((item, index) => {
                                    return (
                                            <option key={index} className={countrySelected === item.countryName && "font-bold w-full" || "w-full"}>
                                            <div>{item.countryName}</div>
                                            </option>
                                    );
                                })}
                            </select>
                            <select onChange={onOptionChangeHandlerTags} className=" flex w-full bg-gray-200 rounded-lg p-1 font-bold">
                                {tags.map((item, index) => {
                                    return (
                                            <option key={index} className={tagSelected === item && "font-bold w-full" || "w-full"}>
                                            <div>{item === "All" ? "All categories" : item}</div>
                                            </option>
                                    );
                                })}
                            </select>
                        </div>

                        <hr className="border-t-1 border-black w-full my-3" />

                        {isLoading && (
                        <div className="h-full flex justify-center">
                            <div className=" self-center spinner-container">
                            <BarLoader color="black" width={200} />
                            </div>
                        </div>
                        )}
                        {!isLoading && (
                        <div className="h-[80%] space-y-3">
                            {articles.filter((article) => filterArticles(article)).length > 0 ?
                            (<div className="grid grid-cols-1 gap-3">
                            {articles.filter((article) => filterArticles(article)).map((item) => (
                                <div className="">
                                    <div className="h-full">
                                        <a href={item.link} target="_blank">
                                            <div
                                            className={`shadow-md border-4 p-1 pb-1 hover:bg-opacity-30 bg-opacity-5 backdrop-blur-[3px] rounded-xl drop-shadow-lg w-full h-full  ${
                                                item.type === "Negative"
                                                ? "bg-custom-red border-custom-red"
                                                : item.type === "Neutral"
                                                ? "bg-custom-yellow2 border-custom-yellow2"
                                                : "bg-custom-green border-custom-green"
                                            }`}
                                            >
                                                <div className="h-full">
                                                    {countrySelected === "World" && (
                                                        <div className="w-full p-1 px-2">
                                                            {coutries.find((country) => country.country_id == item.country_id) && 
                                                                <div className="flex text-left text-black text-sm font-bold p-1 px-2 bg-white bg-opacity-70 rounded-md">
                                                                    <img src={allFlags.find((flag) => flag.countryName == coutries.find((country) => country.country_id == item.country_id).country).flagLogo}
                                                                    className="w-6 h-5 rounded-md mr-2 my-auto border-2"/>
                                                                    <p className="text-left text-black text-sm font-bold">
                                                                    {coutries.find((country) => country.country_id == item.country_id).country}
                                                                    </p>
                                                                </div>
                                                            }
                                                        </div>
                                                    )}

                                                    <div className="flex">
                                                        {item.image && (
                                                        <div className="w-1/3 p-1 px-2">
                                                            <img
                                                            src={item.image}
                                                            alt="illustration of article"
                                                            className="w-full h-[100px] rounded-lg"
                                                            />
                                                        </div>
                                                        ) || (
                                                        <div className="w-1/3 p-1 px-2">
                                                            <img
                                                            src="https://img.freepik.com/premium-vector/breaking-news-tv-banners-white-background_714603-853.jpg"
                                                            alt="illustration of article"
                                                            className="w-full h-[100px] object-cover rounded-lg"
                                                            />
                                                        </div>
                                                        )}
                                                        
                                                        <div className="w-2/3 px-1">
                                                            <div className="float-left">
                                                                <h2 className="text-md font-bold mt-2">
                                                                {item.title.slice(0,45)}...
                                                                </h2>
                                                                <p className="text-gray-600 text-sm font-bold self-center">
                                                                    {item.author}
                                                                </p>
                                                                <p className="text-gray-600 text-md">{item.pubDate}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            ))}
                            </div>)
                            : (
                                <div className="h-full">
                                    <div className="h-full flex justify-center bg-gray-800 bg-opacity-90 text-white rounded-lg">
                                        <div className="self-center">
                                            <div className="flex justify-center">
                                                <img
                                                src="https://i.pinimg.com/originals/52/4c/6c/524c6c3d7bd258cd165729ba9b28a9a2.png"
                                                alt="no data found illustration"
                                                className="h-24"
                                                />
                                            </div>
                                            <div className="flex justify-center">
                                                <div className="font-bold font-custom text-xl w-full text-center">
                                                Not Enough Data
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            )}                            
                            <div className={articlesNumber < allArticles.length ? "flex justify-center" : "hidden"}>
                                <button className="bg-gray-700 bg-opacity-0 hover:bg-opacity-30 border-2 border-gray-700 rounded-lg w-1/3 my-4 font-bold text-lg" onClick={handleLoadMore}>Load More</button>
                            </div>
                        </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
        )}
        <div
      className={`${ //scroll to top button
        isVisible ? 'block' : 'hidden'
      } fixed bottom-4 right-9 bg-white border-2 border-gray-600 p-2 rounded-xl bg-opacity-80 backdrop-blur-[3px] cursor-pointer transition-opacity duration-300`}
      onClick={scrollToTop}
    >
      <img src={scrollButtonImage} alt="Scroll to Top" className="w-6 h-6" />
    </div>
    </div>
  );
}

export default LatestArticles;
