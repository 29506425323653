import React, { useState, useEffect } from "react";
import Navbar from "../components/Navbar";
import backgroundImage from "../assets/backgroundMain.jpg";
import updown from "../assets/uda.svg";
import articleIcon from "../assets/article-icon.png"
import BigSingleHorizontalBar from "../graphs/BigSingleHorizontalBar";
import MicroPieChart from "../graphs/MicroPieChart";
import { useMediaQuery } from "react-responsive";
import Slider from "rc-slider";
import { BarLoader } from "react-spinners";
import updownarrow from "../assets/upDownArrow.svg"

function CountryView() {
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth());
  const [monthwiseData, setMonthwiseData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAllTime, setIsAllTime] = useState(true);
  const [isFirstTime, setIsFirstTime] = useState(true);

  const [countryData, setCountryData] = useState([
    { countryName: "France", positive: 0, negative: 0, neutral: 0 },
    { countryName: "Brazil", positive: 0, negative: 0, neutral: 0 },
    { countryName: "Australia", positive: 0, negative: 0, neutral: 0 },
    { countryName: "China", positive: 0, negative: 0, neutral: 0 },
    { countryName: "USA", positive: 0, negative: 0, neutral: 0 },
    { countryName: "Singapore", positive: 0, negative: 0, neutral: 0 },
    { countryName: "Canada", positive: 0, negative: 0, neutral: 0 },
    { countryName: "Japan", positive: 0, negative: 0, neutral: 0 },
    { countryName: "Nigeria", positive: 0, negative: 0, neutral: 0 },
    { countryName: "Pakistan", positive: 0, negative: 0, neutral: 0 },
    { countryName: "Russia", positive: 0, negative: 0, neutral: 0 },
    { countryName: "UAE", positive: 0, negative: 0, neutral: 0 },
    { countryName: "World", positive: 0, negative: 0, neutral: 0 },
  ]);

  let months = [
    {name: "Jan", number: 1},
    {name: "Feb", number: 2},
    {name: "Mar", number: 3},
    {name: "Apr", number: 4},
    {name: "May", number: 5},
    {name: "Jun", number: 6},
    {name: "Jul", number: 7},
    {name: "Aug", number: 8},
    {name: "Sep", number: 9},
    {name: "Oct", number: 10},
    {name: "Nov", number: 11},
    {name: "Dec", number: 12}
  ];

  const date = new Date();
  const currentMonths = date.getMonth();

  if(currentMonths >= 8){
    months = months.slice(currentMonths-8,currentMonths);
  }
  else{
    months = months.slice(currentMonths+4).concat(months.slice(0, currentMonths));
  }

  const [allFlags, setAllFlags] = useState([]);
  const [allCountryData, setAllCountryData] = useState([]);
  const [worldData, setWorldData] = useState({
    countryName: "World",
    flagLogo: "https://img.freepik.com/free-vector/illustration-global-icon_53876-9267.jpg?size=626&ext=jpg&ga=GA1.1.1546980028.1702252800&semt=ais",
    positive: 0,
    negative: 0,
    neutral: 0,
  });
  const [selectedSort, setSelectedSort] = useState("DEFAULT");
  const [clickCountCountry, setClickCountCountry] = useState(true);
  const [clickCountTotal, setClickCountTotal] = useState(true);
  const [clickCountPositive, setClickCountPosite] = useState(false);
  const [clickCountNeutral, setClickCountNeutral] = useState(false);
  const [clickCountNegative, setClickCountNegative] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the mobile breakpoint
  const isLaptop = useMediaQuery({ minWidth: 780 });

  const handleSliderChange = (value) => {
    setIsAllTime(false);
    setSelectedMonth(value);
    // console.log("Selected Month:", months[value]);
  };
/*
  const handleSortChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedSort(selectedValue);
    console.log(selectedSort);
    if (selectedValue === 'CAZ') {
      // Call the function for sorting by Country A-Z
      sortAlphabeticallyAZ();
    } 
    else if (selectedValue === 'CZA') {
      // Call the function for sorting by Country Z-A
      sortAlphabeticallyZA();
    }
    else if (selectedValue === 'ALH') {
      // Call the function for sorting by articles published Low-Hight
      sortDataByTotalLH();
    }
    else if (selectedValue === 'AHL') {
      // Call the function for sorting by articles published Hight-Low
      sortDataByTotalHL();
    }
    else if (selectedValue === 'NEGLH') {
      // Call the function for sorting by articles published Low-Hight
      sortDataByNegativeLH();
    }
    else if (selectedValue === 'NEGHL') {
      // Call the function for sorting by articles published Hight-Low
      sortDataByNegativeHL();
    }
    else if (selectedValue === 'NEULH') {
      // Call the function for sorting by articles published Low-Hight
      sortDataByNeutralLH();
    }
    else if (selectedValue === 'NEUHL') {
      // Call the function for sorting by articles published Hight-Low
      sortDataByNeutralHL();
    }
    else if (selectedValue === 'POSLH') {
      // Call the function for sorting by articles published Low-Hight
      sortDataByPositiveLH();
    }
    else if (selectedValue === 'POSHL') {
      // Call the function for sorting by articles published Hight-Low
      sortDataByPositiveHL();
    }
  };
*/

  const sliderMarks = months.map((item) => item.name).reduce((acc, month, index) => {
    if (!isAllTime && index === selectedMonth){
      acc[index] = {
        style: { borderColor: "black", height: "5%" }, // Set the style for the vertical line
        label: <b style={{ color: "black", fontSize: "10px" }}>{month}</b>, // Set the label style
      };      
    }
    else {
    acc[index] = {
      style: { borderColor: "grey", height: "5%" }, // Set the style for the vertical line
      label: <p style={{ color: "grey", fontSize: "10px" }}>{month}</p>, // Set the label style
    };
  }
    return acc;
  }, {});

  const handleClickWorld = () => {
    let pos;
    let neu;
    let neg;
    allCountryData.forEach((item) => {
      if (item.countryName === "World")
      {
        pos = item.positive;
        neu = item.neutral;
        neg = item.negative;
      }
    })
    window.localStorage.setItem("hoveredPositive", pos);
    window.localStorage.setItem("hoveredNegative", neg);
    window.localStorage.setItem("hoveredNeutral", neu);
    window.dispatchEvent(new Event("storage"));
  };

  const handleClick = (country) => {
    const aggregatedData = {};

    // Iterate through the monthwiseData array and accumulate data for each country
    monthwiseData.forEach((data) => {
      const { countryName, positive, negative, neutral } = data;

      if (!aggregatedData[countryName]) {
        aggregatedData[countryName] = {
          countryName,
          positive,
          negative,
          neutral,
        };
      } else {
        aggregatedData[countryName].positive += positive;
        aggregatedData[countryName].negative += negative;
        aggregatedData[countryName].neutral += neutral;
      }
    });

    // Convert the aggregatedData object back to an array of objects
    const resultArray = Object.values(aggregatedData);

    const foundCountry = resultArray.find(
      (item) => item.countryName === country.countryName
    );

    window.localStorage.setItem("hoveredCountry", country.countryName);
    window.localStorage.setItem("hoveredPositive", foundCountry.positive);
    window.localStorage.setItem("hoveredNegative", foundCountry.negative);
    window.localStorage.setItem("hoveredNeutral", foundCountry.neutral);
    window.dispatchEvent(new Event("storage"));
  };

  useEffect(() => {
    const fetchAllFlags = async () => {
      setIsLoading(true);
      try {
        const response = await fetch(
          "https://api.kutniti.watch/api/country/getallCountryArticleNumber",
          {
            method: "GET",
          }
        );

        if (response.ok) {
          const getData = await response.json();
          setIsLoading(false);
          const uniqueCountries = [];
          const countryNames = {};

          getData.forEach((item) => {
            const { countryName, flagLogo } = item;
            if (!countryNames[countryName]) {
              countryNames[countryName] = true;
              uniqueCountries.push({ countryName, flagLogo });
            }
          });

          // console.log(uniqueCountries);

          setAllFlags(uniqueCountries);
        } else {
          console.error("API call failed");
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error:", error);
      }
    };

    fetchAllFlags();
    

    const fetchAllCountries = async () => {
      try {
        const response = await fetch(
          "https://api.kutniti.watch/api/country/getallCountryArticlesMonth",
          {
            method: "GET",
          }
        );

        if (response.ok) {
          const getData = await response.json();

          const transformedData = [];

          getData.forEach((dataObj) => {
            dataObj.pubDates
            .filter((item) => {
              const date = new Date(item);
              const month = date.getMonth() + 1;
              const currentDate = new Date();
              if (12 - (8 - currentMonths) <= month && month <= 12){
                if( currentDate.getFullYear() -1 == date.getFullYear() ){
                  return item;
                }
              }
              else if (currentDate.getFullYear() == date.getFullYear()){
                return item;
              }
            })
            .forEach((dateStr) => {
              const date = new Date(dateStr);
              const month = date.getMonth() + 1; // Months are 0-based, so we add 1
              const yearMonth = `${date.getFullYear()}-${month}`;

              const existingEntry = transformedData.find(
                (entry) =>
                  entry.countryName === dataObj.country &&
                  entry.type === dataObj.type &&
                  entry.month === yearMonth
              );
              if (existingEntry) {
                existingEntry.numArticles++;
              } else {
                transformedData.push({
                  countryName: dataObj.country,
                  type: dataObj.type,
                  month: yearMonth,
                  numArticles: 1,
                });
              }
            });
          });

          // console.log(transformedData);

          const combinedData = [];

          transformedData.forEach((dataObj) => {
            const { countryName, month, type, numArticles } = dataObj;

            let existingEntry = combinedData.find(
              (entry) =>
                entry.countryName === countryName && entry.month === month
            );

            if (!existingEntry) {
              existingEntry = {
                countryName,
                month,
                positive: 0,
                negative: 0,
                neutral: 0,
              };
              combinedData.push(existingEntry);
            }

            if (type === "Positive") {
              existingEntry.positive += numArticles;
            } else if (type === "Negative") {
              existingEntry.negative += numArticles;
            } else if (type === "Neutral") {
              existingEntry.neutral += numArticles;
            }
          });

          // console.log(combinedData);

   
          const part1Data = combinedData.map((data) => {
            const monthParts = data.month.split('-');
            const monthNumber = parseInt(monthParts[1], 10);
          
            return {
              countryName: data.countryName,
              month: monthNumber, // Use the numeric month
              positive: data.positive,
              negative: data.negative,
              neutral: data.neutral,
            };
          });
          
          
          
          
          
          function createTemplateObject(countryName, month) {
            return {
              countryName,
              month,
              positive: 0,
              negative: 0,
              neutral: 0,
            };
          }
          const countryDataMap = {};
          
          // Initialize the countryDataMap with the template objects
          part1Data.forEach((data) => {
            if (!countryDataMap[data.countryName]) {
              countryDataMap[data.countryName] = [];
            }
            countryDataMap[data.countryName][data.month - 1] = data;
          });
          
          // Generate the complete set of data for each country
          const finalData = [];
          for (const countryName in countryDataMap) {
            const countryData = countryDataMap[countryName];
          
            for (let month = 1; month <= 12; month++) {
              if (!countryData[month - 1]) {
                // If the month data doesn't exist, create a template object
                const templateObject = createTemplateObject(countryName, month);
                finalData.push(templateObject);
              } else {
                finalData.push(countryData[month - 1]);
              }
            }
          }
                    
          setMonthwiseData(finalData);
         

          //----------------------------------Api data updated till here------------------------------

          //-----------------------Get data for the selectedMonth -----------------------------------------

          const filteredData = finalData
            .filter((item) => parseInt(item.month) === months[selectedMonth].number)
            .map(({ month, ...rest }) => rest);

      

          //--------------------------Send this to countryData--------------------------------------
          setCountryData(filteredData);

          if(isFirstTime)
          {
            // Create an object to store the accumulated data for each country
            const aggregatedData = {};

            // Iterate through the monthwiseData array and accumulate data for each country
            finalData.forEach((data) => {
              const { countryName, positive, negative, neutral } = data;

              if (!aggregatedData[countryName]) {
                aggregatedData[countryName] = {
                  countryName,
                  positive,
                  negative,
                  neutral,
                };
              } else {
                aggregatedData[countryName].positive += positive;
                aggregatedData[countryName].negative += negative;
                aggregatedData[countryName].neutral += neutral;
              }
            });

            // Convert the aggregatedData object back to an array of objects
            const resultArray = Object.values(aggregatedData);
            setCountryData(resultArray);
            setIsFirstTime(false);
          }
          
        } else {
          console.error("API call failed");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchAllCountries();
  }, [selectedMonth]);


  useEffect(() => {

    let worldPositive = 0;
    let worldNeutral = 0;
    let worldNegative = 0;
    countryData.forEach((item) => {
      worldPositive += item.positive;
      worldNeutral += item.neutral;
      worldNegative += item.negative;
    })


    const mergedArray = countryData
      .map((dataItem) => {
        const matchingCountry = allFlags.find(
          (countryItem) => countryItem.countryName === dataItem.countryName
        );

        if (matchingCountry) {
          return {
            countryName: dataItem.countryName,
            flagLogo: matchingCountry.flagLogo,
            positive: dataItem.positive,
            negative: dataItem.negative,
            neutral: dataItem.neutral,
          };
        }

        return null; // If no match found
      })
      .filter((item) => item !== null)
      .sort((a, b) => {
        // Sort by countryName in ascending order
        return a.countryName.localeCompare(b.countryName);
      });

      mergedArray.unshift({
        countryName: "World",
        flagLogo: "https://img.freepik.com/free-vector/illustration-global-icon_53876-9267.jpg?size=626&ext=jpg&ga=GA1.1.1546980028.1702252800&semt=ais",
        positive: worldPositive,
        negative: worldNegative,
        neutral: worldNeutral,
      })
    //console.log(mergedArray);

    setAllCountryData(mergedArray);
    //console.log(mergedArray);

    //console.log(allCountryData)
  }, [countryData, allFlags]);



  const allTimeData = () => {
    // Create an object to store the accumulated data for each country
    setIsAllTime(true);
    const aggregatedData = {};

    // Iterate through the monthwiseData array and accumulate data for each country
    monthwiseData.forEach((data) => {
      const { countryName, positive, negative, neutral } = data;

      if (!aggregatedData[countryName]) {
        aggregatedData[countryName] = {
          countryName,
          positive,
          negative,
          neutral,
        };
      } else {
        aggregatedData[countryName].positive += positive;
        aggregatedData[countryName].negative += negative;
        aggregatedData[countryName].neutral += neutral;
      }
    });

    // Convert the aggregatedData object back to an array of objects
    const resultArray = Object.values(aggregatedData);

    setCountryData(resultArray);

  };

  // console.log(countryData);

  const containerStyle = {
    margin: "0 0 0 0",
    padding: "0 0 0 0",
    backgroundImage: `url(${backgroundImage})`, // Set the background image
    backgroundSize: "cover", // Adjust background sizing
    backgroundRepeat: "no-repeat", // Prevent repeating of background image
    backgroundColor: "#f2f2f2",
    width: "100%",
    height: "100%",
    // Add other styles as needed
  };

  //----------------------------sort Alphabetically--------------------
  const sortAlphabetically = () => {
    setClickCountCountry(!clickCountCountry);
    setClickCountTotal(true);
    setClickCountPosite(false);
    setClickCountNeutral(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountCountry){
       sortedData = [...allCountryData].sort((a, b) => {
        return a.countryName.localeCompare(b.countryName);
      });
    }else{
       sortedData = [...allCountryData].sort((a, b) => {
        return b.countryName.localeCompare(a.countryName);
      });
    }
    setAllCountryData(sortedData);
  };
/*
  const sortAlphabeticallyAZ = () => {
    let sortedData = [...allCountryData].sort((a, b) => {
        return a.countryName.localeCompare(b.countryName);
      });
    setAllCountryData(sortedData);
  };

  const sortAlphabeticallyZA = () => {
    let sortedData = [...allCountryData].sort((a, b) => {
        return b.countryName.localeCompare(a.countryName);
      });
    setAllCountryData(sortedData);
  };
  */
  //----------------------------sort Total--------------------
  const sortDataByTotal = () => {
    setClickCountTotal(!clickCountTotal);
    setClickCountCountry(true);
    setClickCountPosite(false);
    setClickCountNeutral(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountTotal){
      sortedData = [...allCountryData].sort((a, b) => {
        const totalA = a.positive + a.negative + a.neutral;
        const totalB = b.positive + b.negative + b.neutral;
        return totalB - totalA; // Sort in descending order
      });
    }else{
      sortedData = [...allCountryData].sort((a, b) => {
        const totalA = a.positive + a.negative + a.neutral;
        const totalB = b.positive + b.negative + b.neutral;
        return totalA - totalB; // Sort in ascending order
      });
    }
    setAllCountryData(sortedData);
  };
/*
  const sortDataByTotalLH = () => {
    let sortedData = [...allCountryData].sort((a, b) => {
        const totalA = a.positive + a.negative + a.neutral;
        const totalB = b.positive + b.negative + b.neutral;
        return totalA - totalB; // Sort in descending order
      });
    setAllCountryData(sortedData);
  };

  const sortDataByTotalHL = () => {
    let sortedData = [...allCountryData].sort((a, b) => {
        const totalA = a.positive + a.negative + a.neutral;
        const totalB = b.positive + b.negative + b.neutral;
        return totalB - totalA; // Sort in ascending order
      });
    setAllCountryData(sortedData);
  };
 */
  //----------------------------sort Pos--------------------
  const sortDataByPositive = () => {
    setClickCountPosite(!clickCountPositive);
    setClickCountCountry(true);
    setClickCountTotal(true);
    setClickCountNeutral(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountPositive){
      sortedData = [...allCountryData].sort((a, b) => {
        const totalA = (a.positive/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.positive/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    }else{
      sortedData = [...allCountryData].sort((a, b) => {
        const totalA = (a.positive/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.positive/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in dscending order
      });
    }
    setAllCountryData(sortedData);
  };
/*
  const sortDataByPositiveLH = () => {
    let sortedData = [...allCountryData].sort((a, b) => {
        const totalA = (a.positive/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.positive/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    setAllCountryData(sortedData);
  };

  const sortDataByPositiveHL = () => {
    let sortedData = [...allCountryData].sort((a, b) => {
        const totalA = (a.positive/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.positive/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in ascending order
      });
    setAllCountryData(sortedData);
  };
*/
  //----------------------------sort Negative--------------------
  const sortDataByNegative = () => {
    setClickCountNegative(!clickCountNegative);
    setClickCountCountry(true);
    setClickCountTotal(true);
    setClickCountPosite(false);
    setClickCountNeutral(false);

    let sortedData;
    if(clickCountNegative){
      sortedData = [...allCountryData].sort((a, b) => {
        const totalA = (a.negative/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.negative/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    }else{
      sortedData = [...allCountryData].sort((a, b) => {
        const totalA = (a.negative/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.negative/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in descending order
      });
    }
    setAllCountryData(sortedData);
  };
/*
  const sortDataByNegativeLH = () => {
    let sortedData = [...allCountryData].sort((a, b) => {
        const totalA = (a.negative/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.negative/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    setAllCountryData(sortedData);
  };

  const sortDataByNegativeHL = () => {
    let sortedData = [...allCountryData].sort((a, b) => {
        const totalA = (a.negative/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.negative/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in ascending order
      });
    setAllCountryData(sortedData);
  };
*/
  //----------------------------sort neutral--------------------
  const sortDataByNeutral = () => {
    setClickCountNeutral(!clickCountNeutral);
    setClickCountCountry(true);
    setClickCountTotal(true);
    setClickCountPosite(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountNeutral){
      sortedData = [...allCountryData].sort((a, b) => {
        const totalA = (a.neutral/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.neutral/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    }else{
      sortedData = [...allCountryData].sort((a, b) => {
        const totalA = (a.neutral/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.neutral/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in desscending order
      });
    }
    setAllCountryData(sortedData);
  };
/*
  const sortDataByNeutralLH = () => {
    let sortedData = [...allCountryData].sort((a, b) => {
        const totalA = (a.neutral/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.neutral/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    setAllCountryData(sortedData);
  };
  
  const sortDataByNeutralHL = () => {
    let sortedData = [...allCountryData].sort((a, b) => {
        const totalA = (a.neutral/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.neutral/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in ascending order
      });
    setAllCountryData(sortedData);
  };
*/
  return (
    <div id="" style={containerStyle} className="font-custom">
      <Navbar />
      {isLaptop && (
        <div>
          <div className="flex min-h-screen pt-2">
            <div className="m-8 flex min-h-screen rounded-2xl border border-gray-600 w-full mt-20">
              <div className="p-5 pt-2 w-full">
                <div className="flex mb-5 mt-2 justify-between">
                  <h2 className="text-[25px] self-center w-1/2 font-bold">
                    Countries ranked by their perception of India
                  </h2>
                  <div className="max-h-20 w-1/2 inline-flex rounded-2xl shadow-2xl border border-black-800 backdrop-blur-[3px] bg-white bg-opacity-30 p-0 justify-between">
                    <div className="pb-7 pt-3 px-5 w-5/6">
                      <div className="ml-2 mt-2">
                        <Slider
                          min={0}
                          max={7}
                          marks={sliderMarks}
                          step={1}
                          value={isAllTime ? null :selectedMonth}
                          onChange={handleSliderChange}
                          railStyle={{ backgroundColor: "black" }}
                          trackStyle={{ backgroundColor: "black" }}
                          handleStyle={isAllTime ? null :{
                            borderColor: "black",

                            width: 10,
                            height: 10,
                            marginTop: -2,
                            borderRadius: 4,
                            backgroundColor: "black",
                            border: "2px solid black",
                          }}
                        />
                      </div>
                    </div>

                    <div className="flex items-center justify-center w-1/6">
                      <button
                        onClick={allTimeData}
                        className={`${isAllTime ? "font-bold" : ""} bg-black border-2 border-black text-white text-sm rounded-xl px-3 py-2  mr-2 w-30`}
                      >
                        All Time
                      </button>
                    </div>
                  </div>
                </div>

                <div className="bg-white bg-opacity-30 backdrop-blur-[3px] shadow-2xl border border-gray-300 rounded-2xl p-10 flex min-h-[85%]">
                <div className="w-full">
                  <div className="flex mb-4 justify-between">
                  
                      <button className="flex w-1/5 justify-center items-center p-1 rounded-lg border-2 border-gray-400 bg-gray-400 bg-opacity-0 hover:bg-opacity-30" onClick={sortAlphabetically}>
                        <div className="font-semibold ">
                          Country
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>

                      <button className="flex w-1/6 justify-center items-center p-1 rounded-lg border-2 border-gray-400 bg-gray-400 bg-opacity-0 hover:bg-opacity-30"  onClick={sortDataByTotal}>
                        <h2 className=" font-semibold ">
                          Articles Published
                        </h2>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>

                    <div className="flex justify-between w-1/2 pl-7">
                      {/* <div className=" font-semibold ">Perception</div> */}
                      <button className="flex w-1/4 justify-center items-center p-1 rounded-lg border-2 border-custom-red bg-custom-red bg-opacity-0 hover:bg-opacity-30"  onClick={sortDataByNegative}>
                        <div className="cursor-pointer font-semibold">
                          Negative
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>

                      <button className="flex w-1/4 justify-center items-center p-1 rounded-lg border-2 border-custom-yellow2 bg-custom-yellow2 bg-opacity-0 hover:bg-opacity-30" onClick={sortDataByNeutral}>
                        <div className="cursor-pointer font-semibold">
                          Neutral
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>

                      <button className="flex w-1/4 justify-center items-center p-1 rounded-lg border-2 border-custom-green bg-custom-green bg-opacity-0 hover:bg-opacity-30" onClick={sortDataByPositive}>
                        <div className="cursor-pointer ml-3 font-semibold">
                          Positive
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>
                    </div>
                  </div>

                  <hr className="border-t-1 border-black w-full" />

                  {isLoading && (
                    <div className="flex h-full justify-center">
                      <div className="self-center spinner-container mt-10">
                        <BarLoader color="black" width={200} />
                      </div>
                    </div>
                  )}
                  {!isLoading && (
                    <div>
                      {allCountryData.map((country, index) => {
                        if (country.countryName === "World")
                        {
                          return (
                            <a
                              className=" hover:cursor-pointer"
                              onMouseDown={() => handleClickWorld()}
                              href="/country-detail"
                            >
                            <a href="/world">
                              <div className="flex justify-between bg-[#9dbad4] rounded-lg transform hover:scale-105 my-1 px-3 p-2 hover:shadow-2xl  hover:rounded-lg hover:transition-transform transition hover:duration-500 ">
                                <div className="flex w-1/5">
                                  <div className="self-center shadow-sm shadow-black rounded-lg overflow-hidden">
                                    {country.flagLogo && (
                                      <img
                                        src={country.flagLogo}
                                        alt="Country Flag"
                                        className="h-12 w-20"
                                      />
                                    )}
                                  </div>

                                  <h2 className="self-center text-lg ml-5 font-semibold">
                                    {country.countryName}
                                  </h2>
                                </div>

                                <div className="flex w-1/6 justify-center">
                                  <h2 className="self-center text-sm font-semibold mr-1">
                                  {country.positive +
                                    country.negative +
                                    country.neutral}
                                  </h2>
                                  <div className="self-center mr-2 overflow-hidden">
                                  <img
                                    src={articleIcon}
                                    alt="Article Icon"
                                    className="w-[25px] h-[25px]"
                                  />
                                  </div>
                                </div>

                                <div className="self-center w-1/2 ">
                                  {country.negative === 0 &&
                                    country.positive === 0 &&
                                    country.neutral === 0 ? (
                                    <div className="flex h-full">
                                      <div className="invisible">t Enough Data</div>
                                      <div className="self-center">Not Enough Data</div>
                                    </div>
                                  ) : (
                                    <BigSingleHorizontalBar
                                      key={country.countryName}
                                      positiveValue={country.positive}
                                      negativeValue={country.negative}
                                      neutralValue={country.neutral}
                                    />
                                  )}
                                </div>
                              </div>
                              <hr className="border-t-1 border-black w-full" />
                            </a>
                            </a>
                          )
                        }
                        return (
                          <a
                            className=" hover:cursor-pointer"
                            key={index}
                            onMouseDown={() => handleClick(country)}
                            href="/country-detail"
                          >
                            <div className="flex justify-between transform hover:scale-105 m-2 p-1 hover:shadow-2xl  hover:rounded-lg hover:transition-transform transition hover:duration-500 ">
                              <div className="flex w-1/5">
                                <div className="self-center shadow-sm shadow-black rounded-lg overflow-hidden">
                                  {country.flagLogo && (
                                    <img
                                      src={country.flagLogo}
                                      alt="Country Flag"
                                      className="h-12 w-20 object-cover"
                                    />
                                  )}
                                </div>

                                <h2 className="self-center text-lg ml-5 font-semibold">
                                  {country.countryName}
                                </h2>
                              </div>

                              <div className="flex w-1/6 justify-center">
                                <h2 className="self-center text-sm font-semibold mr-1">
                                {country.positive +
                                  country.negative +
                                  country.neutral}
                                </h2>
                                <div className="self-center mr-2 overflow-hidden">
                                <img
                                  src={articleIcon}
                                  alt="Article Icon"
                                  className="w-[25px] h-[25px]"
                                />
                                </div>
                              </div>

                              <div className="self-center w-1/2 ">
                                {country.negative === 0 &&
                                country.positive === 0 &&
                                country.neutral === 0 ? (
                                  <div className="flex h-full">
                                    <div className="invisible">t Enough Data</div>
                                    <div className="self-center">Not Enough Data</div>
                                  </div>
                                ) : (
                                  <BigSingleHorizontalBar
                                    key={country.countryName}
                                    positiveValue={country.positive}
                                    negativeValue={country.negative}
                                    neutralValue={country.neutral}
                                  />
                                )}
                              </div>
                              {/* <div className="p-0 ml-20 w-auto ">Map Area</div> */}
                            </div>
                            <hr className="border-t-1 border-black w-full" />
                          </a>
                      )})}
                    </div>
                  )}
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {isMobile && (
        <div>
          <div className="py-8 invisible">Hidden Text Area</div>
          <div className="p-2 mt-2 p-1 w-full">
            <div className=" my-1">
              <h2 className="text-xl text-center text-gray-600 font-bold mb-4">
                Countries ranked by their perception of India
              </h2>
            </div>

            <div className="mb-2 w-full inline-flex rounded-2xl border border-black-800 bg-white p-0 justify-between">
              <div className="mx-1 px-2 pt-2 w-full overflow-x-scroll">
                <div className=" w-500">
                  <div
                    className=" w-full max-w-full pl-2 pr-2"// overflow-x-scroll "
                    style={{ maxWidth: "370px" }}
                  >
                    <Slider
                      min={0}
                      max={7}
                      marks={sliderMarks}
                      step={1}
                      value={isAllTime ? null :selectedMonth}
                      onChange={handleSliderChange}
                      railStyle={{ backgroundColor: "black" }}
                      trackStyle={{ backgroundColor: "black" }}
                      handleStyle={isAllTime ? null : {
                        borderColor: "black",
                        width: 10,
                        height: 10,
                        marginTop: -2,
                        borderRadius: 4,
                        backgroundColor: "black",
                        border: "2px solid black",
                      }}
                    />
                    <div className="invisible">{selectedMonth}</div>
                  </div>
                </div>
              </div>

              <div>
                <button
                  onClick={allTimeData}
                  className={`${isAllTime ? "font-bold" : ""} border-2 border-black bg-black text-white rounded-xl p-1  p-1 my-2 mr-1 w-20`}
                >
                  All Time
                </button>
              </div>
            </div>

            {/*<div className="mb-5 w-full inline-flex rounded-2xl border border-black-800 bg-white p-0 justify-between">
              <select className="w-full" value={selectedSort} onChange={handleSortChange}>
                <option value="DEFAULT">Sort by ...</option>
                <option value="CAZ">Country A-Z</option>
                <option value="CZA">Country Z-A</option>
                <option value="ALH">Articles Published Low-Hight</option>
                <option value="AHL">Articles Published Hight-Low</option>
                <option value="NEGLH">Negative Low-Hight</option>
                <option value="NEGHL">Negative Hight-Low</option>
                <option value="NEULH">Neutral Low-Hight</option>
                <option value="NEUHL">Neutral Hight-Low</option>
                <option value="POSLH">Positive Low-Hight</option>
                <option value="POSHL">Positive Hight-Low</option>
              </select>
            </div>*/}
            
            <div className="mt-1 items-center min-h-screen">
              <div className="flex justify-between">

                <div className="flex w-1/3 px-1 justify-center" onClick={sortAlphabetically}>
                  <div className="self-end">
                    <div className="flex">Country<img src={updown} alt="" className="ml-1 cursor-pointer" /></div>
                    <hr className="border-2 border-gray-500 w-full" />
                  </div>
                </div>

                <div className="flex w-1/3 px-1 justify-center " onClick={sortDataByTotal}>
                  <div className="self-end">
                    <div className="flex text-xs pb-1">Articles pub.<img  src={updown} alt="" className="ml-1 cursor-pointer" /></div>
                    <hr className="border-2 border-gray-500 w-full" />
                  </div>
                </div>

                <div className="flex w-1/5 px-1 justify-center" onClick={sortDataByNegative}>
                  <div>
                    <div className="flex">Neg.<img  src={updown} alt="" className="ml-1 cursor-pointer" /></div>
                    <hr className="border-2 border-custom-red w-full" />
                  </div>
                </div>

                <div className="flex w-1/5 px-1 justify-center" onClick={sortDataByNeutral}>
                  <div>
                    <div className="flex">Neu.<img src={updown} alt="" className="ml-1 cursor-pointer" /></div>
                    <hr className="border-2 border-custom-yellow2 w-full" />
                  </div>
                </div>

                <div className="flex w-1/5 px-1 justify-center" onClick={sortDataByPositive}>
                  <div>
                    <div className="flex">Pos.<img src={updown} alt="" className="ml-1 cursor-pointer" /></div>
                    <hr className="border-2 border-custom-green w-full" />
                  </div>
                </div>
              </div>
              <hr className="border-t-1 mt-3 mb-3 border-black w-full" />

              {isLoading && (
                <div
                  style={{
                    display: "flex",
                    position: "relative",
                    justifyContent: "center",
                    alignItems: "center",
                    zIndex: "2",
                  }}
                >
                  <div className="spinner-container mt-10">
                    <BarLoader color="black" width={100} />
                  </div>
                </div>
              )}
              {!isLoading && (
                <div>
                  {allCountryData.map((country, index) => {
                    if (country.countryName === "World")
                    {
                      return (
                        <a
                          className=" hover:cursor-pointer"
                          onMouseDown={() => handleClickWorld()}
                          href="/country-detail"
                        >
                        <a href="/world">
                          <div className="flex justify-between bg-[#9dbad4] rounded-lg transform hover:scale-105 my-1 hover:shadow-2xl  hover:rounded-lg hover:transition-transform transition hover:duration-500 ">
                          <div className="justify-start w-1/3">
                          <div className="ml-4">
                            {country.flagLogo && (
                              <img
                                src={country.flagLogo}
                                alt="Country Flag"
                                className="w-16 h-8 mt-2 w-1/6"
                              />
                            )}
                            <div className="w-16 flex justify-center">
                            <h2 className=" font-bold my-1">{country.countryName}</h2>
                            </div>
                          </div>
                        </div>

                        <div className="flex w-1/3 justify-center">
                          <div className="self-center">
                          <img
                            src={articleIcon}
                            alt="Article Icon"
                            className="w-[25px] h-[25px]"
                          />
                          <p className="text-sm text-center mt-px">{country.positive+country.negative+country.neutral}</p>
                          </div>
                        </div>

                        <div className="flex w-1/5 justify-center">
                        <div className="self-center">
                          <MicroPieChart
                            key={country.countryName}
                            hoveredPositive={Math.floor((country.negative/(country.positive+country.negative+country.neutral))*100)}
                            hoveredNegative={100 - Math.floor((country.negative/(country.positive+country.negative+country.neutral))*100)}
                            fillType="negative"
                          />
                          <p className="text-sm">{country.positive+country.negative+country.neutral === 0 ? 0
                                                            :Math.floor((country.negative/(country.positive+country.negative+country.neutral))*100)}%</p>
                        </div>
                        </div>

                        <div className="flex w-1/5 justify-center ">
                        <div className="self-center">
                          <MicroPieChart
                            key={country.countryName}
                            hoveredPositive={Math.floor((country.neutral/(country.positive+country.negative+country.neutral))*100)}
                            hoveredNegative={100 - Math.floor((country.neutral/(country.positive+country.negative+country.neutral))*100)}
                            fillType="neutral"
                          />
                          <p className="text-sm">{country.positive+country.negative+country.neutral === 0 ? 0
                                                            :Math.floor((country.neutral/(country.positive+country.negative+country.neutral))*100)}%</p>
                        </div>
                        </div>

                        <div className="flex w-1/5 justify-center ">
                          <div className="self-center">
                          <MicroPieChart
                            key={country.countryName}
                            hoveredPositive={Math.floor((country.positive/(country.positive+country.negative+country.neutral))*100)}
                            hoveredNegative={100 - Math.floor((country.positive/(country.positive+country.negative+country.neutral))*100)}
                            fillType="positive"
                          />
                          <p className="text-sm">{country.positive+country.negative+country.neutral === 0 ? 0
                                                            :Math.floor((country.positive/(country.positive+country.negative+country.neutral))*100)}%</p>
                          </div>
                        </div>
                          </div>
                          <hr className="border-t-1 border-black w-full" />
                        </a>
                        </a>
                      )
                    }
                    return (
                      <a className="" onMouseDown={() => handleClick(country)} href="/country-detail">
                        <div>
                          {country.positive+country.negative+country.neutral === 0 ? (
                            <div key={index} className="flex justify-between border backdrop-blur-[3px] border-gray-300 bg-opacity-40 bg-gray-200 rounded-lg shadow-lg">
                              <div className="ml-4 w-1/3">
                                {country.flagLogo && (
                                  <img
                                    src={country.flagLogo}
                                    alt="Country Flag"
                                    className="w-16 h-8 mt-2 w-1/6 shadow-sm shadow-black object-cover"
                                  />
                                )}
                                <div className="w-16 flex justify-center">
                                <h2 className=" font-bold my-1">{country.countryName}</h2>
                                </div>
                              </div>
                              <div className="w-2/3 self-center">
                                <div className="flex justify-center">Not Enough Data</div>
                              </div>
                            </div>
                          ) : (
                            <div key={index} className="flex justify-between border backdrop-blur-[3px] border-gray-300 bg-opacity-40 bg-gray-200 rounded-lg shadow-lg ">
                              <div className="justify-start w-1/3">
                                <div className="ml-4">
                                  {country.flagLogo && (
                                    <img
                                      src={country.flagLogo}
                                      alt="Country Flag"
                                      className="w-16 h-8 mt-2 w-1/6"
                                    />
                                  )}
                                  <div className="w-16 flex justify-center">
                                  <h2 className=" font-bold my-1">{country.countryName}</h2>
                                  </div>
                                </div>
                              </div>

                              <div className="flex w-1/3 justify-center">
                                <div className="self-center">
                                <img
                                  src={articleIcon}
                                  alt="Article Icon"
                                  className="w-[25px] h-[25px]"
                                />
                                <p className="text-sm text-center mt-px">{country.positive+country.negative+country.neutral}</p>
                                </div>
                              </div>

                              <div className="flex w-1/5 justify-center">
                              <div className="self-center">
                                <MicroPieChart
                                  key={country.countryName}
                                  hoveredPositive={Math.floor((country.negative/(country.positive+country.negative+country.neutral))*100)}
                                  hoveredNegative={100 - Math.floor((country.negative/(country.positive+country.negative+country.neutral))*100)}
                                  fillType="negative"
                                />
                                <p className="text-sm">{country.positive+country.negative+country.neutral === 0 ? 0
                                                                  :Math.floor((country.negative/(country.positive+country.negative+country.neutral))*100)}%</p>
                              </div>
                              </div>

                              <div className="flex w-1/5 justify-center ">
                              <div className="self-center">
                                <MicroPieChart
                                  key={country.countryName}
                                  hoveredPositive={Math.floor((country.neutral/(country.positive+country.negative+country.neutral))*100)}
                                  hoveredNegative={100 - Math.floor((country.neutral/(country.positive+country.negative+country.neutral))*100)}
                                  fillType="neutral"
                                />
                                <p className="text-sm">{country.positive+country.negative+country.neutral === 0 ? 0
                                                                  :Math.floor((country.neutral/(country.positive+country.negative+country.neutral))*100)}%</p>
                              </div>
                              </div>

                              <div className="flex w-1/5 justify-center ">
                                <div className="self-center">
                                <MicroPieChart
                                  key={country.countryName}
                                  hoveredPositive={Math.floor((country.positive/(country.positive+country.negative+country.neutral))*100)}
                                  hoveredNegative={100 - Math.floor((country.positive/(country.positive+country.negative+country.neutral))*100)}
                                  fillType="positive"
                                />
                                <p className="text-sm">{country.positive+country.negative+country.neutral === 0 ? 0
                                                                  :Math.floor((country.positive/(country.positive+country.negative+country.neutral))*100)}%</p>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                        <hr className="border-t-1 mt-3 mb-3 border-black w-full" />
                      </a>
                    )
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default CountryView;
