import React, { useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import "../Styles/Staff.css";
import PieChartComponent from "../graphs/PieChartComponent";
import html2canvas from "html2canvas";
import CountryDsipoCard from "../components/CountryDsipoCard";
import CountryEcoCard from "../components/CountryEcoCard";
import share from "../assets/shareButton.png";
import copy from "../assets/copy.png";
import check from "../assets/check.png";
import facebook from "../assets/facebook.svg";
import twitter from "../assets/twitter.svg";
import linkedin from "../assets/linkedin.svg";
import backgroundImage from "../assets/backgroundMain.jpg";
import franceMap from "../assets/franceMap.png";
import usaMap from "../assets/usaMap.png";
import ukMap from "../assets/ukMap.png";
import japanMap from "../assets/japanMap.png";
import russiaMap from "../assets/russiaMap.png";
import chinaMap from "../assets/chinaMap.png";
import canadaMap from "../assets/canadaMap.png";
import brazilMap from "../assets/brazilMap.png";
import singaporeMap from "../assets/singaporeMap.png";
import uaeMap from "../assets/uaeMap.png";
import nigeriaMap from "../assets/nigeriaMap.png";
import australiaMap from "../assets/australiaMap.png";
import pakistanMap from "../assets/pakistanMap.png";
import AreaChartComponent from "../graphs/AreaChartComponent";
import SmallBarChartComponent from "../graphs/SmallBarChartComponent";
import BigSingleHorizontalBar from "../graphs/BigSingleHorizontalBar";
import { useMediaQuery } from "react-responsive";
import SmallPieChart from "../graphs/SmallCopyPieChart";
import MicroPieChart from "../graphs/MicroPieChart";

import updown from "../assets/uda.svg";
import updownarrow from "../assets/upDownArrow.svg"
import articleIcon from "../assets/article-icon.png"

import fiveEyesImg from "../assets/countryStats/five eyes 1.svg";
import bricsImg from "../assets/countryStats/brics 1.svg";
import nuclearImg from "../assets/countryStats/nuclear 1.svg";
import qsdImg from "../assets/countryStats/qsd 1.svg";
import unscImg from "../assets/countryStats/unsc 1.svg";
import borDispImg from "../assets/countryStats/shield 1.svg";

import CountryImportCard from "../components/CountryImportCard";
import CountryExportCard from "../components/CountryExportCard";
import CountryDefenseCard from "../components/CountryDefenseCard";
import CountryTourismCard from "../components/CountryTourismCard";

function CountryDetails() {
  const [dataForBar, setDataForBar] = useState([]);

  const [newspaperData, setNewspaperData] = useState([]);

  const [countryData, setCountryData] = useState({
    name: "",
    all: 0,
    positive: 0,
    negative: 0,
    neutral: 0,
  });
  const [flagObjectSelected, setFlagObjectSelected] = useState("");
  const [countryStats, setCountryStats] = useState([]);
  const [isWideScreen, setIsWideScreen] = useState(false);
  const [positiveSmall, setPositiveSmall] = useState(0);
  const [negativeSmall, setNegativeSmall] = useState(0);
  const [neutralSmall, setNeutralSmall] = useState(0);

  const [brics, setBrics] = useState(false);
  const [fiveEyes, setFiveEyes] = useState(false);
  const [unsc, setUnsc] = useState(false);
  const [qsd, setQsd] = useState(false);
  const [nuclear, setNuclear] = useState(false);
  const [borDisp, setBorDisp] = useState(false);

  const [ecoRank, setEcoRank] = useState();
  const [diaspRank, setDiaspRank] = useState();
  const [importRank, setImportRank] = useState();
  const [exportRank, setExportRank] = useState();
  const [defenseRank, srtDefenseRank] = useState();
  const [tourismRank, setTourismRank] = useState();

  const [bricsHover, setBricsHover] = useState(false);
  const [fiveEyesHover, setFiveEyesHover] = useState(false);
  const [unscHover, setUnscHover] = useState(false);
  const [qsdHover, setQsdHover] = useState(false);
  const [nuclearHover, setNuclearHover] = useState(false);
  const [borDispHover, setBorDispHover] = useState(false);

  const [ecoRankHover, setEcoRankHover] = useState(false);
  const [diaspRankHover, setDiaspRankHover] = useState(false);
  const [importRankHover, setImportRankHover] = useState(false);
  const [exportRankHover, setExportRankHover] = useState(false);
  const [defenseRankHover, setDefenseRankHover] = useState(false);
  const [tourismRankHover, setTourismRankHover] = useState(false);

  const [clickCountNews, setClickCountNews] = useState(true);
  const [clickCountCountry, setClickCountCountry] = useState(true);
  const [clickCountTotal, setClickCountTotal] = useState(true);
  const [clickCountPositive, setClickCountPosite] = useState(false);
  const [clickCountNeutral, setClickCountNeutral] = useState(false);
  const [clickCountNegative, setClickCountNegative] = useState(false);

  const [tagNumber, setTagNumber] = useState(0);

  const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the mobile breakpoint
  const isLaptop = useMediaQuery({ minWidth: 780 });


  let tempName;

  useEffect(() => {
    // Update the state based on the window width when the component mounts
    const handleResize = () => {
      setIsWideScreen(window.innerWidth > 1380);
    };

    // Add an event listener to update the state when the window is resized
    window.addEventListener("resize", handleResize);

    // Initialize the state based on the window width
    handleResize();

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    tempName = localStorage.getItem("hoveredCountry");
    const tempPositive = localStorage.getItem("hoveredPositive");
    const tempNegative = localStorage.getItem("hoveredNegative");
    const tempNeutral = localStorage.getItem("hoveredNeutral");

    const getStats = async () => {
      // try {
      //     const res = await fetch(
      //       "https://sheet.best/api/sheets/4a6a3f85-83ed-4537-886d-02d28e3b5696"
      //     );
      //     const data = await res.json();
      //     setCountryStats(Object.keys(data).map((key) => data[key]));
      //   } catch (error) {
      //     console.log(error);
      //   }
      try {
        const res = await fetch(
          //"https://api.kutniti.watch/api/country/getallCountryDatas",
          "https://api.kutniti.watch/api/country/getallCountryDatas",
          {
            method: "GET",
          }
        );
        const data = await res.json();
        setCountryStats(Object.keys(data).map((key) => data[key]));
      } catch (error) {
        console.log(error);
      }
    };
    getStats();

    async function fetchNewspapers() {
      try {
        const response = await fetch(
          "https://api.kutniti.watch/api/newspaper/getAllNewspapers"
        );

        if (!response.ok) {
          throw new Error("Network response was not ok");
        }

        const data = await response.json();
        const newArrayOfObjects = data.flatMap((newspaper) =>
          Object.entries(newspaper.articles).map(([month, stats]) => ({
            newspaper_id: newspaper.newspaper_id,
            newspaper_name: newspaper.newspaper_name,
            link: newspaper.link,
            country: newspaper.country,
            logo: newspaper.logo,
            month: monthToNumber(month), // Convert month to number (e.g., "June" to 6)
            positive: stats.Positive || 0, // Set to 0 if not present
            negative: stats.Negative || 0, // Set to 0 if not present
            neutral: stats.Neutral || 0, // Set to 0 if not present
            monthly_readers:newspaper.monthly_readers,
            political_inclination: newspaper.political_inclination,
            basic_info: newspaper.basic_info,
            owner: newspaper.owner,
          }))
        );

        function monthToNumber(month) {
          // Map month names to numbers
          const monthMap = {
            January: 1,
            February: 2,
            March: 3,
            April: 4,
            May: 5,
            June: 6,
            July: 7,
            August: 8,
            September: 9,
            October: 10,
            November: 11,
            December: 12,
          };

          return monthMap[month] || 0; // Default to 0 if month is not found
        }

        //---------Now make objects for remaining months with 0s in positive neg and neu---

        const monthlyData = [];

        // Iterate over each object in the original array
        newArrayOfObjects.forEach((item) => {
          // Iterate over 12 months
          for (let month = 1; month <= 12; month++) {
            // Check if the month already exists in the original data
            const existingMonthData = newArrayOfObjects.find(
              (x) => x.newspaper_id === item.newspaper_id && x.month === month
            );

            if (existingMonthData) {
              // If the month exists, push the existing data
              monthlyData.push(existingMonthData);
            } else {
              // If the month does not exist, create a new object with zeros
              monthlyData.push({
                ...item,
                month: month,
                positive: 0,
                negative: 0,
                neutral: 0,
              });
            }
          }
        });
        //----------------------------sTORE ALL MONTHS DATA IN monthwiseData------------------------------------
        // Remove duplicates
        const uniqueMonthlyData = monthlyData.filter((item, index, self) => {
          // Find the index of the first occurrence of the current item
          const firstIndex = self.findIndex(
            (otherItem) =>
              otherItem.newspaper_name === item.newspaper_name &&
              otherItem.month === item.month
          );

          // Keep the current item only if its index is the same as the first index found
          return index === firstIndex;
        });

        const aggregatedData = {};

        uniqueMonthlyData.forEach((data) => {
          const { newspaper_name, positive, negative, neutral, logo, country , monthly_readers, political_inclination, basic_info, owner} =
            data;

          if (!aggregatedData[newspaper_name]) {
            aggregatedData[newspaper_name] = {
              newspaper_name,
              positive,
              negative,
              neutral,
              logo,
              country,
              monthly_readers,
              political_inclination,
              basic_info,
              owner,
            };
          } else {
            aggregatedData[newspaper_name].positive += positive;
            aggregatedData[newspaper_name].negative += negative;
            aggregatedData[newspaper_name].neutral += neutral;
          }
        });

        // Convert the aggregatedData object back to an array of objects
        const resultArray = Object.values(aggregatedData);

        // ----------------------------Filter only the newspapers with country as tempName---------------------

        if (tempName === "United States") {
          tempName = "USA";
        }

        const filteredArray = resultArray.filter(
          (obj) => obj.country === tempName
        );

        if (tempName === "USA") {
          tempName = "United States";
        }

        setNewspaperData(filteredArray);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    }

    fetchNewspapers();

    const fetchAllFlags = async () => {
      try {
        const response = await fetch(
          "https://api.kutniti.watch/api/country/getallCountryArticleNumber",
          {
            method: "GET",
          }
        );

        if (response.ok) {
          const getData = await response.json();

          const uniqueCountries = [];
          const countryNames = {};

          getData.forEach((item) => {
            const { countryName, flagLogo, } = item;
            if (!countryNames[countryName]) {
              countryNames[countryName] = true;
              uniqueCountries.push({ countryName, flagLogo});
            }
          });

          // console.log(uniqueCountries);
          if (tempName === "United States") {
            tempName = "USA";
          }

          const matchedCountry = uniqueCountries.find(
            (country) => country.countryName === tempName
          );
          setFlagObjectSelected(matchedCountry);

          if (tempName === "USA") {
            tempName = "United States";
          }
        } else {
          console.error("API call failed");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchAllFlags();

    setCountryData({
      name: tempName,
      positive: tempPositive,
      negative: tempNegative,
      neutral: tempNeutral,
    });

    const fetchAllCountries = async () => {
      try {
        const requestData = {
          countryName: tempName,
        };

        const response = await fetch(
          "https://api.kutniti.watch/api/country/getoneCountryArticlesMonth",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(requestData),
          }
        );

        if (response.ok) {
          const getData = await response.json();

          const transformedData = [];

          getData.forEach((dataObj) => {
            dataObj.pubDates.forEach((dateStr) => {
              const date = new Date(dateStr);
              const month = date.getMonth() + 1; // Months are 0-based, so we add 1
              const yearMonth = `${date.getFullYear()}-${month}`;

              const existingEntry = transformedData.find(
                (entry) =>
                  entry.countryName === dataObj.country &&
                  entry.type === dataObj.type &&
                  entry.month === yearMonth
              );
              if (existingEntry) {
                existingEntry.numArticles++;
              } else {
                transformedData.push({
                  countryName: dataObj.country,
                  type: dataObj.type,
                  month: yearMonth,
                  numArticles: 1,
                });
              }
            });
          });


          const combinedData = [];

          transformedData.forEach((dataObj) => {
            const { countryName, month, type, numArticles } = dataObj;

            let existingEntry = combinedData.find(
              (entry) =>
                entry.countryName === countryName && entry.month === month
            );

            if (!existingEntry) {
              existingEntry = {
                countryName,
                month,
                positive: 0,
                negative: 0,
                neutral: 0,
              };
              combinedData.push(existingEntry);
            }

            if (type === "Positive") {
              existingEntry.positive += numArticles;
            } else if (type === "Negative") {
              existingEntry.negative += numArticles;
            } else if (type === "Neutral") {
              existingEntry.neutral += numArticles;
            }
          });

          const part1Data = combinedData.map((data) => {
            const monthParts = data.month.split("-");
            const monthNumber = parseInt(monthParts[1], 10);

            return {
              countryName: data.countryName,
              month: monthNumber,
              positive: data.positive,
              negative: data.negative,
              neutral: data.neutral,
            };
          });

          function createTemplateObject(countryName, month) {
            return {
              countryName,
              month,
              positive: 0,
              negative: 0,
              neutral: 0,
            };
          }
          const countryDataMap = {};

          // Initialize the countryDataMap with the template objects
          part1Data.forEach((data) => {
            if (!countryDataMap[data.countryName]) {
              countryDataMap[data.countryName] = [];
            }
            countryDataMap[data.countryName][data.month - 1] = data;
          });

          const date = new Date();
          const currentMonths = date.getMonth(); 

          let months = [
            {name: "Jan", number: 1},
            {name: "Feb", number: 2},
            {name: "Mar", number: 3},
            {name: "Apr", number: 4},
            {name: "May", number: 5},
            {name: "Jun", number: 6},
            {name: "Jul", number: 7},
            {name: "Aug", number: 8},
            {name: "Sep", number: 9},
            {name: "Oct", number: 10},
            {name: "Nov", number: 11},
            {name: "Dec", number: 12}
          ];
        
          if(currentMonths >= 8){
            months = months.slice(currentMonths-8,currentMonths);
          }
          else{
            months = months.slice(currentMonths+4).concat(months.slice(0, currentMonths));
          }

          // Generate the complete set of data for each country
          const newData = [];
          for (const countryName in countryDataMap) {
            const countryData = countryDataMap[countryName];
            
            months.forEach((month) => {
              if (!countryData[month.number-1]) {
                // If the month data doesn't exist, create a template object
                const templateObject = createTemplateObject(countryName, month.number);
                newData.push(templateObject);
              } else {
                newData.push(countryData[month.number - 1]);
              }
            });
          }

          const transformedArray = newData.map((item) => {
            const { month, positive, negative, neutral } = item;
            const name = months.find((monthItem) => monthItem.number === month).name; // Adjusting for 0-based index

            return {
              name,
              pos: positive,
              neg: negative,
              neu: neutral,
              max: 0,
            };
          });
          setDataForBar(transformedArray);

        } else {
          console.error("API call failed");
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchAllCountries();

  }, [tempName]);

  useEffect(() => {
    const tempPositive = localStorage.getItem("hoveredPositive");
    const tempNegative = localStorage.getItem("hoveredNegative");
    const tempNeutral = localStorage.getItem("hoveredNeutral");

    setPositiveSmall(tempPositive);
    setNegativeSmall(tempNegative);
    setNeutralSmall(tempNeutral);
  }, [countryData]);

  useEffect(() => {
    //--------Map through countryStats looking for item.brics, item.about etc  and if tempName matches update its state-----------------------------------

    const tempContName = localStorage.getItem("hoveredCountry");

    let i = 0;
    countryStats.forEach((country) => {
      if (country.unsc === 'Yes' && tempContName === country.countryName) {
        i++;
        setUnsc(true);
      }
      if (country.brics === 'Yes' && tempContName === country.countryName) {
        i++;
        setBrics(true);
      }
      if (country.nuclear === 'Yes' && tempContName === country.countryName) {
        i++;
        setNuclear(true);
      }
      if (country.fiveEyes === 'Yes' && tempContName === country.countryName) {
        i++;
        setFiveEyes(true);
      }
      if (country.qsd === 'Yes' && tempContName === country.countryName) {
        i++;
        setQsd(true);
      }
      if (country.borDisp === 'Yes' && tempContName === country.countryName) {
        i++;
        setBorDisp(true);
      }
      if (country.ecoRank > 0 && tempContName === country.countryName) {
        i++;
      }
      if (country.diaspRank > 0 && tempContName === country.countryName) {
        i++;
      }
      if (country.importRank > 0 && tempContName === country.countryName) {
        i++;
      }
      if (country.exportRank > 0 && tempContName === country.countryName) {
        i++;
      }
      if (country.defenseRank > 0 && tempContName === country.countryName) {
        i++;
      }
      if (country.tourismRank > 0 && tempContName === country.countryName) {
        i++;
      }
      if (i > 0){
        setTagNumber(i);
        i=0
      }
    });

    // Map through countryStats looking for tempContName attribute in each object and extract 1st object's value as ...

    countryStats.forEach((item) => {
      //console.log(item.countryName)

      if (item.countryName == tempContName) {
        setEcoRank(item.ecoRank);
        setDiaspRank(item.diaspRank);
        setImportRank(item.importRank);
        setExportRank(item.exportRank);
        srtDefenseRank(item.defenseRank);
        setTourismRank(item.tourismRank);
        //console.log(item)
      }
    });
  }, [countryStats]);



  // const shareText = "Check out this awesome pie chart!"; // Change as needed
  // const shareUrl = encodeURIComponent("http://localhost:3000/country-view"); // Get the current URL

  // const handleDownload = async () => {
  //   const chartRef = document.getElementById("capture"); // Get the chart element

  //   try {
  //     const canvas = await html2canvas(chartRef);
  //     const imageUri = canvas.toDataURL("image/png");

  //     const link = document.createElement("a");
  //     link.href = imageUri;
  //     link.download = "capture.png";
  //     link.click();
  //   } catch (error) {
  //     console.error("Error generating image:", error);
  //   }
  // };

  let bg = backgroundImage;
  if (countryData.name === "France")
  {
    bg = franceMap;
  } else if (countryData.name === "USA")
  {
    bg = usaMap;
  } else if (countryData.name === "UK")
  {
    bg = ukMap;
  } else if (countryData.name === "Japan")
  {
    bg = japanMap;
  } else if (countryData.name === "China")
  {
    bg = chinaMap;
  } else if (countryData.name === "Canada")
  {
    bg = canadaMap;
  } else if (countryData.name === "Australia")
  {
    bg = australiaMap;
  } else if (countryData.name === "Brazil")
  {
    bg = brazilMap;
  } else if (countryData.name === "Russia")
  {
    bg = russiaMap;
  } else if (countryData.name === "Pakistan")
  {
    bg = pakistanMap;
  } else if (countryData.name === "UAE")
  {
    bg = uaeMap;
  } else if (countryData.name === "Singapore")
  {
    bg = singaporeMap;
  } else if (countryData.name === "Nigeria")
  {
    bg = nigeriaMap;
  }
  const containerStyle = {
    margin: "0 0 0 0",
    padding: "0 0 0 0",
    backgroundImage: `url(${bg})`, // Set the background image
    backgroundSize: "cover", // Adjust background sizing
    backgroundRepeat: "no-repeat", // Prevent repeating of background image
    backgroundColor: "#f2f2f2",
    backgroundPosition: 'center',
    // Add other styles as needed
  };

  const handleMouseEnterEco = () => {
    setEcoRankHover(true);
  };

  const handleMouseLeaveEco = () => {
    setEcoRankHover(false);
  };

  const handleMouseEnterDiasp = () => {
    setDiaspRankHover(true);
  };

  const handleMouseLeaveDiasp = () => {
    setDiaspRankHover(false);
  };

  const handleMouseEnterImport = () => {
    setImportRankHover(true);
  };

  const handleMouseLeaveImport = () => {
    setImportRankHover(false);
  };

  const handleMouseEnterDefense = () => {
    setDefenseRankHover(true);
  };

  const handleMouseLeaveDefense = () => {
    setDefenseRankHover(false);
  };

  const handleMouseEnterTourism = () => {
    setTourismRankHover(true);
  };

  const handleMouseLeaveTourism = () => {
    setTourismRankHover(false);
  };

  const handleMouseEnterExport = () => {
    setExportRankHover(true);
  };

  const handleMouseLeaveExport = () => {
    setExportRankHover(false);
  };

  const handleMouseEnterBrics = () => {
    setBricsHover(true);
  };

  const handleMouseLeaveBrics = () => {
    setBricsHover(false);
  };

  const handleMouseEnterQsd = () => {
    setQsdHover(true);
  };

  const handleMouseLeaveQsd = () => {
    setQsdHover(false);
  };

  const handleMouseEnterUnsc = () => {
    setUnscHover(true);
  };

  const handleMouseLeaveUnsc = () => {
    setUnscHover(false);
  };

  const handleMouseEnterBord = () => {
    setBorDispHover(true);
  };

  const handleMouseLeaveBord = () => {
    setBorDispHover(false);
  };

  const handleMouseEnterFiveEyes = () => {
    setFiveEyesHover(true);
  };

  const handleMouseLeaveFiveEyes = () => {
    setFiveEyesHover(false);
  };

  const handleMouseEnterNuclear = () => {
    setNuclearHover(true);
  };

  const handleMouseLeaveNuclear = () => {
    setNuclearHover(false);
  };

  const handleClick = (newspaper) => {
    window.localStorage.setItem("hoveredNewspaper", newspaper.newspaper_name);
    window.localStorage.setItem(
      "hoveredNewspaperPositive",
      newspaper.positive
    );
    window.localStorage.setItem(
      "hoveredNewspaperNegative",
      newspaper.negative
    );
    window.localStorage.setItem(
      "hoveredNewspaperNeutral",
      newspaper.neutral
    );
    window.localStorage.setItem(
      "hoveredNewspaperCountry",
      newspaper.country
    );
    window.localStorage.setItem("hoveredNewspaperLogo", newspaper.logo);
    window.localStorage.setItem(
      "hoveredNewspaperReaders",
      newspaper.monthly_readers
    );
    window.localStorage.setItem(
      "hoveredNewspaperPoliticalInclination",
      newspaper.political_inclination
    );
    window.localStorage.setItem(
      "hoveredNewspaperInfo",
      newspaper.basic_info
    );
    window.localStorage.setItem(
      "hoveredNewspaperOwner",
      newspaper.owner
    );

    window.dispatchEvent(new Event("storage"));
    window.location.href = "/newspaper-detail";
  }

  const colors = [
    "bg-red-600",
    "bg-orange-400",
    "bg-yellow-300",
    "bg-green-300",
    "bg-green-600",
  ];
  const texts = ["0%", "25%", "50%", "75%", "100%"];

  // function handleTweet() {
  //   let total = parseInt(countryData.positive)+parseInt(countryData.neutral)+parseInt(countryData.positive);
  //   let NegPercentage = parseInt((countryData.negative/total)*100)

  //   const message = encodeURIComponent(`Since May 2023, ${NegPercentage}% of ${countryData.name}'s medias were negative towards India.
  //   \nWant to know more? Check on : `);
  //   const twitterUrl = `https://twitter.com/intent/tweet?text=${message}&url=https://kutniti-watch.com`;
  //   window.open(twitterUrl, "_blank");
  // }

  // console.log(countryData);

  //////////////////////////Share handling/////////////////:
  let total = parseInt(countryData.positive)+parseInt(countryData.neutral)+parseInt(countryData.positive);
  let NegPercentage = parseInt((countryData.negative/total)*100)
  const websiteUrl = `https://kutniti.watch`
  const message = encodeURIComponent("Do you want to know how India is perceived in the world?\nGo check on:");

  function handleTweet() {
    const twitterUrl = `https://twitter.com/intent/tweet?text=${message}&url=${websiteUrl}`;
    window.open(twitterUrl, "_blank");
  }

  function handleFb(){
    const fbUrl = `https://www.facebook.com/sharer/sharer.php?u=${websiteUrl}`;
    window.open(fbUrl, "_blank")
  }

  function handleLinkedIn()
  {
    const LinkedInUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${websiteUrl}`;
    window.open(LinkedInUrl, "_blank")
  }

  function handleCopy(){
    const currentUrl = window.location.href;
            const tempTextarea = document.createElement("textarea");
            tempTextarea.value = currentUrl;
            document.body.appendChild(tempTextarea);
            tempTextarea.select();
            document.execCommand("copy");
            document.body.removeChild(tempTextarea);

            const button = document.getElementById("CopyButton");
            const imgElement = button.querySelector('img'); // Find the img element within the button
            imgElement.src = check; 

            // You may want to reset the button text after a certain time (e.g., 2 seconds)
            setTimeout(() => {
              imgElement.src = copy;
            }, 3000);
  }

  const captureScreenshot = async () => {
    const canvas = await html2canvas(document.body);
    return canvas.toDataURL(); // Returns a data URL representing the screenshot
  };

  async function toggleShareOptions() {
    try {
      const screenshotDataUrl = await captureScreenshot();
      if (navigator.share) {
        await navigator.share({
          title: 'Share Screenshot',
          text: 'Check out this screenshot!',
          url: screenshotDataUrl,
        });
      } else {
        var shareOptions = document.getElementById("shareOptions");
        if (shareOptions.style.display === "block") {
            shareOptions.style.display = "none";
        } else {
            shareOptions.style.display = "block";
        }
      }
    } catch (error) {
      console.error('Error sharing screenshot:', error);
    }
}

  //----------------------------sort Alphabetically newspaper--------------------
  const sortAlphabeticallyNewspaper = () => {
    setClickCountNews(!clickCountNews);
    setClickCountCountry(true);
    setClickCountTotal(true);
    setClickCountPosite(false);
    setClickCountNeutral(false);
    setClickCountNegative(false);

    let sortedData;
    if (clickCountNews) {
      sortedData = [...newspaperData].sort((a, b) => {
        return a.newspaper_name.localeCompare(b.newspaper_name);
      });
    } else {
      sortedData = [...newspaperData].sort((a, b) => {
        return b.newspaper_name.localeCompare(a.newspaper_name);
      });
    }

    setNewspaperData(sortedData);
  };

  //----------------------------sort Total--------------------
  const sortDataByTotal = () => {
    setClickCountTotal(!clickCountTotal);
    setClickCountNews(true);
    setClickCountCountry(true);
    setClickCountPosite(false);
    setClickCountNeutral(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountTotal){
      sortedData = [...newspaperData].sort((a, b) => {
        const totalA = a.positive + a.negative + a.neutral;
        const totalB = b.positive + b.negative + b.neutral;
        return totalB - totalA; // Sort in descending order
      });
    }else{
      sortedData = [...newspaperData].sort((a, b) => {
        const totalA = a.positive + a.negative + a.neutral;
        const totalB = b.positive + b.negative + b.neutral;
        return totalA - totalB; // Sort in descending order
      });
    }

    setNewspaperData(sortedData);
  };

  //----------------------------sort Positive--------------------
  const sortDataByPositive = () => {
    setClickCountPosite(!clickCountPositive);
    setClickCountNews(true);
    setClickCountTotal(true);
    setClickCountNeutral(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountPositive){
      sortedData = [...newspaperData].sort((a, b) => {
        const totalA = (a.positive/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.positive/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    }else{
      sortedData = [...newspaperData].sort((a, b) => {
        const totalA = (a.positive/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.positive/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in dscending order
      });
    }
    setNewspaperData(sortedData);

  };

  //----------------------------sort Negative--------------------
  const sortDataByNegative = () => {
    setClickCountNegative(!clickCountNegative);
    setClickCountNews(true);
    setClickCountTotal(true);
    setClickCountPosite(false);
    setClickCountNeutral(false);

    let sortedData;
    if(clickCountNegative){
      sortedData = [...newspaperData].sort((a, b) => {
        const totalA = (a.negative/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.negative/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    }else{
      sortedData = [...newspaperData].sort((a, b) => {
        const totalA = (a.negative/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.negative/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in descending order
      });
    }
    setNewspaperData(sortedData);
  };
  
  //----------------------------sort Neutral--------------------
  const sortDataByNeutral = () => {
    setClickCountNeutral(!clickCountNeutral);
    setClickCountNews(true);
    setClickCountTotal(true);
    setClickCountPosite(false);
    setClickCountNegative(false);

    let sortedData;
    if(clickCountNeutral){
      sortedData = [...newspaperData].sort((a, b) => {
        const totalA = (a.neutral/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.neutral/(b.positive + b.neutral + b.negative))*100;
        return totalA - totalB; // Sort in ascending order
      });
    }else{
      sortedData = [...newspaperData].sort((a, b) => {
        const totalA = (a.neutral/(a.positive + a.neutral + a.negative))*100;
        const totalB = (b.neutral/(b.positive + b.neutral + b.negative))*100;
        return totalB - totalA; // Sort in desscending order
      });
    }
    setNewspaperData(sortedData);
  };

  return (
    <div style={containerStyle} className="w-full font-custom">
      <Navbar />
      <div id="capture" className="flex ">
        <div className="w-full m-2 lg:m-7 p-2 mb-5">
          <h1 className="p-5 invisible">
            Providing Free spacing
          </h1>

          <div className="mt-2 lg:p-5  p-2 mb-5 rounded-xl border border-gray-600">
            <div className="">
              <div className="lg:w-full bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 flex justify-between items-center rounded-xl shadow-2xl p-1 px-2">
                <div className="flex m-1">
                  <div className="rounded-lg overflow-hidden ">
                    {flagObjectSelected && (
                      <img
                        src={flagObjectSelected.flagLogo}
                        alt="Country Flag"
                        className=" h-10 rounded-lg"
                      />
                    )}
                  </div>

                  <div className="text-xl ml-2 flex items-center justify-center">
                    <p className="text-2xl font-bold my-auto">{countryData.name}</p>
                  </div>
                </div>

                <div className="flex">
                  <div id="shareOptions" class="hidden flex">
                          <div className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleFb}>
                          <img
                            src={facebook}
                            alt="Facebook Button"
                            className="w-18 h-10 rounded-lg"
                          />
                          </div>
                          <div className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleTweet}>
                          <img
                            src={twitter}
                            alt="Twitter Button"
                            className="w-18 h-10 rounded-lg"
                          />
                          </div>
                          <div className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleLinkedIn}>
                            <img
                              src={linkedin}
                              alt="LinkedIn Button"
                              className="w-18 h-10 rounded-lg"
                            />
                          </div>
                          <div id="CopyButton" className="mx-2 cursor-pointer transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300" onClick={handleCopy}>
                            <img
                                src={copy}
                                alt="Copy Button"
                                className="w-18 h-10 rounded-lg"
                              />
                          </div>
                  </div>
                  <button
                    className="bg-white cursor-pointer text-white font-bold m-auto p-auto rounded-lg shadow-lg transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-110 duration-300 border"
                    onClick={toggleShareOptions}
                  >
                    <img
                      src={share}
                      alt="Share Button"
                      className="w-18 h-10 rounded-lg"
                    />
                  </button>
                </div>
              </div>
            </div>

            <div className={tagNumber == 0 ? "hidden" : "bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 items-center rounded-xl shadow-lg p-2 h-30 mt-5"}>
              <div className="text-2xl">
                <div className={isLaptop && tagNumber <= 4 ? "flex justify-between" : ""}>
                  <p className={isLaptop && tagNumber <= 4 ? "mx-2 mt-2 mb-4 w-[40%] text-left": "mx-2 mt-2 mb-4 text-center lg:text-left"}>
                    Why {countryData.name} matters to India?
                  </p>
                  <div className="flex justify-between">
                    <div className="grid grid-cols-2 gap-4 lg:grid-cols-6">
                      {diaspRank == 0 ? null : (
                        <div className=""
                          onMouseEnter={handleMouseEnterDiasp}
                          onMouseLeave={handleMouseLeaveDiasp}
                        >
                          <CountryDsipoCard
                            firstValue={diaspRank}
                            secondValue="Indian Diaspora in the World"
                          />
                        </div>
                      )}

                      {ecoRank == 0 ? null : (
                        <div className=""
                          onMouseEnter={handleMouseEnterEco}
                          onMouseLeave={handleMouseLeaveEco}
                        >
                          <CountryEcoCard
                            firstValue={ecoRank}
                            secondValue="Economic Power in the World"
                          />
                        </div>
                      )}

                      {importRank == 0 ? null : (
                        <div className=""
                          onMouseEnter={handleMouseEnterImport}
                          onMouseLeave={handleMouseLeaveImport}
                        >
                          <CountryImportCard
                            firstValue={importRank}
                            secondValue="Import partner of India"
                          />
                        </div>
                      )}

                      {exportRank == 0 ? null : (
                        <div className=""
                          onMouseEnter={handleMouseEnterExport}
                          onMouseLeave={handleMouseLeaveExport}
                        >
                          <CountryExportCard
                            firstValue={exportRank}
                            secondValue="Export partner of India"
                          />
                        </div>
                      )}

                      {defenseRank == 0 ? null : (
                        <div className=""
                          onMouseEnter={handleMouseEnterDefense}
                          onMouseLeave={handleMouseLeaveDefense}
                        >
                          <CountryDefenseCard
                            firstValue={defenseRank}
                            secondValue="Defence provider to India"
                          />
                        </div>
                      )}

                      {tourismRank == 0 ? null : (
                        <div className=""
                          onMouseEnter={handleMouseEnterTourism}
                          onMouseLeave={handleMouseLeaveTourism}
                        >
                          <CountryTourismCard
                            firstValue={tourismRank}
                            secondValue="Nation visiting India for tourism"
                          />
                        </div>
                      )}

                      {brics && (
                        <div className="bg-opacity-50 bg-white backdrop-blur-[3px] w-30 h-20 rounded-lg shadow-2xl border p-2 pt-1"
                          onMouseEnter={handleMouseEnterBrics}
                          onMouseLeave={handleMouseLeaveBrics}
                        >
                          <div className="flex justify-center items-center">
                            <img
                              src={bricsImg}
                              alt="Relation Image"
                              className="w-13 h-8 ml-1"
                            />
                          </div>
                          <div className="flex justify-center text-center text-gray-600 text-sm">
                            BRICS founding member
                          </div>
                        </div>
                      )}

                      {borDisp && (
                        <div className="bg-opacity-50 bg-white backdrop-blur-[3px] w-30 h-20 rounded-lg shadow-2xl border p-2 flex flex-col justify-center items-center"
                          onMouseEnter={handleMouseEnterBord}
                          onMouseLeave={handleMouseLeaveBord}
                        >
                          <div className="flex justify-center items-center">
                            <img
                              src={borDispImg}
                              alt="Relation Image"
                              className="w-13 h-8 ml-1"
                            />
                          </div>
                          <div className="flex justify-center text-center text-gray-600 text-sm">
                            Border dispute with India
                          </div>
                        </div>
                      )}

                      {unsc && (
                        <div className="bg-opacity-50 bg-white backdrop-blur-[3px] w-30 h-20 rounded-lg shadow-2xl border p-2 pt-1"
                          onMouseEnter={handleMouseEnterUnsc}
                          onMouseLeave={handleMouseLeaveUnsc}
                        >
                          <div className="flex justify-center items-center">
                            <img
                              src={unscImg}
                              alt="Relation Image"
                              className="w-15 h-12 ml-1 mt-1"
                            />
                          </div>
                          <div className="flex justify-center text-center text-gray-600 text-sm">
                            Member of UNSC
                          </div>
                        </div>
                      )}

                      {nuclear && (
                        <div className="bg-opacity-50 bg-white backdrop-blur-[3px] w-30 h-20 rounded-lg shadow-2xl border p-1"
                          onMouseEnter={handleMouseEnterNuclear}
                          onMouseLeave={handleMouseLeaveNuclear}
                        >
                          <div className="flex justify-center items-center">
                            <img
                              src={nuclearImg}
                              alt="Relation Image"
                              className="w-13 h-12 ml-1"
                            />
                          </div>
                          <div className="flex justify-center text-center text-gray-600 text-sm">
                            Nuclear Power
                          </div>
                        </div>
                      )}

                      {fiveEyes && (
                        <div className="bg-opacity-50 bg-white backdrop-blur-[3px] w-30 h-20 rounded-lg shadow-2xl border p-2 pt-1"
                          onMouseEnter={handleMouseEnterFiveEyes}
                          onMouseLeave={handleMouseLeaveFiveEyes}
                        >
                          <div className="flex justify-center items-center">
                            <img
                              src={fiveEyesImg}
                              alt="Relation Image"
                              className="w-13 h-8 ml-1"
                            />
                          </div>
                          <div className="flex justify-center text-center text-gray-600 text-sm">
                            {isLaptop ? "Member of Five Eyes Intelligence Alliance" : "Members of five eyes"}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                </div>
                <div className="w-full mt-4 lg:h-12">
                  {ecoRankHover ? 
                    <div className="flex justify-center transition bg-black text-xs text-white font-bold rounded-lg bg-opacity-100 text-opacity-100 w-full h-full">
                      <div className="self-center text-center m-2 ">Economic power ranking refers to the assessment and ranking of countries or entities based on their economic strength, typically considering factors like GDP, trade, and industrial output. It helps to gauge a nation's influence, competitiveness, and potential impact on the global economy</div>
                    </div>
                  :
                  null
                  }
                  {diaspRankHover ? 
                    <div className="flex justify-center transition bg-black text-xs text-white font-bold rounded-lg bg-opacity-100 text-opacity-100 w-full">
                      <div className="self-center text-center m-2 ">The Indian diaspora ranking refers to the assessment or categorization of people of Indian origin living outside India based on their population size, influence, contributions to their host countries, and connections to India. The data is represented in descending order in terms of the population of the Indian Diaspora residing in these countries. This ranking helps us to understand the scale and impact of the Indian diaspora globally.</div>
                    </div>
                  :
                  null
                  }
                  {importRankHover ? 
                    <div className="flex justify-center transition bg-black text-xs text-white font-bold rounded-lg bg-opacity-100 text-opacity-100 w-full">
                      <div className="self-center text-center m-2 ">The import ranking of a country refers to the position of a country based on the value or volume of goods and services it imports from India. It reflects the demand for Indian products and can have its impact on India’s economy and trade policies.</div>
                    </div>
                  :
                  null
                  }
                  {exportRankHover ? 
                    <div className="flex justify-center transition bg-black text-xs text-white font-bold rounded-lg bg-opacity-100 text-opacity-100 w-full">
                      <div className="self-center text-center m-2 ">The export ranking of a country in terms of India refers to the position of a country based on the value or volume of goods and services it exports to India. It helps identify key trading partners and potential areas for collaboration and growth.</div>
                    </div>
                  :
                  null
                  }
                  {tourismRankHover ? 
                    <div className="flex justify-center transition bg-black text-xs text-white font-bold rounded-lg bg-opacity-100 text-opacity-100 w-full">
                      <div className="self-center text-center m-2 ">Tourism ranking of nations visiting India refers to the list that ranks countries based on the number of tourists they send to India. It showcases which countries contribute the most visitors to India's tourism industry, providing insights into international travel patterns and preferences.</div>
                    </div>
                  :
                  null
                  }
                  {defenseRankHover ? 
                    <div className="flex justify-center transition bg-black text-xs text-white font-bold rounded-lg bg-opacity-100 text-opacity-100 w-full">
                      <div className="self-center text-center m-2 ">
                          The Defense Provider to India ranking assesses countries based on their significance as suppliers of defense equipment.</div>
                    </div>
                  :
                  null
                  }
                  {bricsHover ? 
                    <div className="flex justify-center transition bg-black text-xs text-white font-bold rounded-lg bg-opacity-100 text-opacity-100 w-full">
                      <div className="self-center text-center m-2 ">BRICS stands for Brazil, Russia, India, China, and South Africa. It's an acronym for a group of five major emerging economies known for their significant influence in global affairs. The nations collaborate on various economic, political, and strategic initiatives to enhance mutual cooperation and address common challenges on the international stage.</div>
                    </div>
                  :
                  null
                  }
                  {qsdHover ? 
                    <div className="flex justify-center transition bg-black text-xs text-white font-bold rounded-lg bg-opacity-100 text-opacity-100 w-full">
                      <div className="self-center text-center m-2 ">The Quadrilateral Security Dialogue, is an informal strategic forum between the USA, Japan, India, and Australia aimed at enhancing cooperation on regional security and addressing common challenges in the Indo-Pacific region. It involves discussions on various issues like maritime security, counterterrorism and promoting a free and open Indo-Pacific.</div>
                    </div>
                  :
                  null
                  }
                  {unscHover ? 
                    <div className="flex justify-center transition bg-black text-xs text-white font-bold rounded-lg bg-opacity-100 text-opacity-100 w-full">
                      <div className="self-center text-center m-2 ">The United Nations Security Council (UNSC) is the most powerful part of the UN responsible for maintaining international peace and security. It has 5 permanent members (US, UK, China, Russia, France) with veto power.</div>
                    </div>
                  :
                  null
                  }
                  {borDispHover ? 
                    <div className="flex justify-center transition bg-black text-xs text-white font-bold rounded-lg bg-opacity-100 text-opacity-100 w-full">
                      <div className="self-center text-center m-2 ">The border dispute between India involves territorial disagreements primarily along the Line of Actual Control (LAC) in regions like Kashmir, Arunachal Pradesh, and other areas. It's a complex issue rooted in historical, geopolitical, and cultural factors, leading to occasional tensions and conflicts between India and some of its neighbouring countries, notably China and Pakistan.</div>
                    </div>
                  :
                  null
                  }
                  {nuclearHover ? 
                    <div className="flex justify-center transition bg-black text-xs text-white font-bold rounded-lg bg-opacity-100 text-opacity-100 w-full">
                      <div className="self-center text-center m-2 ">
                      A nuclear power is a country that posses nuclear weapons or has the capability to produce and deploy them. These weapons are extremely powerful and have the potential to cause great destruction therefore, possessionof nuclear weapons can have serious implications for the country’s foreign policy and national security.</div>
                    </div>
                  :
                  null
                  }
                  {fiveEyesHover ? 
                    <div className="flex justify-center transition bg-black text-xs text-white font-bold rounded-lg bg-opacity-100 text-opacity-100 w-full">
                      <div className="self-center text-center m-2 ">Five Eyes is the largest intelligence alliance in the world comprising the US, UK, Canada, Australia, and New Zealand. They share intelligence and collaborate on surveillance, gathering information, and security matters.</div>
                    </div>
                  :
                  null
                  }
                  {(ecoRankHover || diaspRankHover || importRankHover || exportRankHover || tourismRankHover || defenseRankHover ||
                    bricsHover || qsdHover || unscHover || borDispHover || nuclearHover || fiveEyesHover) ? 
                    null
                  :
                    <div className="flex justify-center transition bg-gray-300 text-xs text-gray-700 font-bold rounded-lg bg-opacity-100 text-opacity-100 w-full h-full">
                      <div className="self-center text-center m-2 ">{isLaptop ? "Hover a card to know more" : "Click on a card to know more"}</div>
                    </div>
                  }
                </div>
              </div>
            </div>
            <div
              className={` ${
                isWideScreen
                  ? "flex w-full space-x-12"
                  : "grid grid-cols-1 lg:grid-cols-2 lg-8"
              }  overflow-x-auto mb-8`}
            >
              {/* 1 */}
              <div className={`${isWideScreen ? "w-1/3" : "max-w-[520px]"}`}>
                {isMobile && (
                  <div className="bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 items-center shadow-lg rounded-xl mt-5 mb-2 ">
                    <div className="m-2">
                      <div className="flex text-center  w-full text-xl ">
                        Sentiment of {countryData.name} towards India
                      </div>
                      <div className="flex">
                        <div className="w-1/2 flex justify-end">
                          <SmallPieChart
                            hoveredPositive={countryData.positive}
                            hoveredNegative={countryData.negative}
                            hoveredNeutral={countryData.neutral}
                          />
                          {/* {console.log(countryData.positive)} */}
                        </div>

                        <div className="w-1/2 self-center">
                          <p className="text-custom-red m-1">
                            Negative: {countryData.negative}
                          </p>
                          <p className="text-custom-yellow2 m-1">
                            Neutral: {countryData.neutral}
                          </p>
                          <p className="text-custom-green m-1">
                            Positive: {countryData.positive}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {isLaptop && (
                  <div className="bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 items-center shadow-lg rounded-xl w-full max-h-[400px] flex mb-5 mt-2 r-0">
                    <div className="pb-2 mx-auto">
                      <p className="flex justify-center text-xl mt-5">
                        Sentiment of {countryData.name} towards India
                      </p>
                      <div className="">
                        <div className="flex justify-center">
                        <PieChartComponent
                            hoveredPositive={countryData.positive}
                            hoveredNegative={countryData.negative}
                            hoveredNeutral={countryData.neutral}
                            // hoveredPositive={2}
                            // hoveredNegative={2}
                            // hoveredNeutral={2}
                          />
                        </div>
                        {/* {console.log(countryData.negative)} */}
                        <div className="flex justify-between">
                          <p className="text-red-500">
                            Negative: {countryData.negative}
                          </p>
                          <p className="text-yellow-300">
                            Neutral: {countryData.neutral}
                          </p>
                          <p className="text-green-500">
                            Positive: {countryData.positive}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div>
                  {isMobile && (
                    <div className="p-2 flex bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 shadow-md rounded-xl max-w-[500px]">
                      <p className="text-gray-700 mx-4 ">
                        Articles Published by {countryData.name}
                      </p>

                      <div className="w-px h-6 bg-gray-800  self-center"></div>
                      <p className="text-gray-700 text-base mx-4 self-center">
                        {parseInt(countryData.positive) +
                          parseInt(countryData.negative) +
                          parseInt(countryData.neutral)}
                      </p>
                    </div>
                  )}
                  {isLaptop && (
                    <div className="border border-gray-300 bg-white bg-opacity-30 backdrop-blur-[3px] p-2 flex justify-center space-x-4  shadow-md rounded-xl w-full">
                      <p className="text-gray-700 ">
                        Articles Published by {countryData.name}
                      </p>

                      <div className="w-px h-6 bg-gray-800"></div>
                      <p className="text-gray-700 text-base">
                        {parseInt(countryData.positive) +
                          parseInt(countryData.negative) +
                          parseInt(countryData.neutral)}
                      </p>
                    </div>
                  )}
                </div>
              </div>

              {/* 2 */}

              <div className={` ${isWideScreen ? "w-2/3" : "w-full h-1/3"}  mt-2 mb-2`}>
                <div className=" bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 m-auto shadow-md rounded-xl w-full h-1000 overflow-x-auto">
                <p className="flex justify-center text-xl mt-5">
                  Evolution over the months
                </p>
                {isLaptop && (<AreaChartComponent chartData={dataForBar} />)}
                {isMobile && (<AreaChartComponent chartData={dataForBar} />)}
                </div>
              </div>
            </div>

            <div className="flex">
              {isLaptop && (
                <div className="w-full bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 m-auto shadow-md rounded-xl p-4">
                  <div className=" my-1">
                    <h2 className="text-2xl font-bold mb-5  ">
                      Most Read Newspapers of {countryData.name}
                    </h2>
                  </div>

                  <div className="flex justify-between mb-4">

                    <div className="w-1/5">
                      <button className="flex w-full px-5 justify-center items-center p-1 rounded-lg border-2 border-gray-400 bg-gray-400 bg-opacity-0 hover:bg-opacity-30" onClick={sortAlphabeticallyNewspaper}>
                        <div className="font-semibold ">
                          Newspaper
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>
                    </div>

                    <div className="w-1/6">
                      <button className="flex w-full px-2 justify-center items-center p-1 rounded-lg border-2 border-gray-400 bg-gray-400 bg-opacity-0 hover:bg-opacity-30" onClick={sortDataByTotal}>
                        <div className="font-semibold ">
                          Articles Published
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>
                    </div>

                    <div className="flex justify-between w-1/2 px-2">

                      <button className="flex w-1/4 justify-center items-center p-1 rounded-lg border-2 border-custom-red bg-custom-red bg-opacity-0 hover:bg-opacity-30"  onClick={sortDataByNegative}>
                        <div className="cursor-pointer font-semibold">
                          Negative
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>
                      
                      <button className="flex w-1/4 justify-center items-center p-1 rounded-lg border-2 border-custom-yellow2 bg-custom-yellow2 bg-opacity-0 hover:bg-opacity-30" onClick={sortDataByNeutral}>
                        <div className="cursor-pointer font-semibold">
                          Neutral
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>
                      
                      <button className="flex w-1/4 justify-center items-center p-1 rounded-lg border-2 border-custom-green bg-custom-green bg-opacity-0 hover:bg-opacity-30" onClick={sortDataByPositive}>
                        <div className="cursor-pointer ml-3 font-semibold">
                          Positive
                        </div>
                        <img src={updownarrow} alt="" className="ml-2 h-6 cursor-pointer" />
                      </button>

                    </div>
                  </div>

                  <hr className="border-t-1 border-black w-full" />

                  {newspaperData && (
                    <div className=" items-center">
                      {newspaperData.map((newspaper, index) => (
                        <div className="mt-4 mb-4">
                          <div key={index} className="flex justify-between transform hover:scale-105 m-2 p-1 hover:cursor-pointer hover:shadow-2xl  hover:rounded-lg  hover:transition-transform transition hover:duration-500"
                          onClick={() => handleClick(newspaper)}>

                            <div className="flex w-1/5">
                              <div className="self-center mr-2 w-2/3 overflow-hidden">
                                {newspaper.logo && (
                                  <img
                                    src={newspaper.logo}
                                    alt={"Logo: "+newspaper.newspaper_name}
                                    className="h-10"
                                  />
                                ) ||
                                <h2 className="text-sm self-center font-semibold">
                                {newspaper.newspaper_name}
                                </h2>
                                }
                              </div>
                            </div>

                            <div className="self-center">
                              <img
                                src={articleIcon}
                                alt="Article Icon"
                                className="w-[25px] h-[25px]"
                              />
                              <p className="text-sm text-center mt-px">{newspaper.positive+newspaper.negative+newspaper.neutral}</p>
                            </div>

                            <div className="w-1/2">
                              <BigSingleHorizontalBar
                                positiveValue={newspaper.positive}
                                negativeValue={newspaper.negative}
                                neutralValue={newspaper.neutral}
                              />
                            </div>
                          </div>
                          <hr className="border-t-2 border-black w-full" />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}

              {isMobile && (
                <div className=" mt-5 w-full">
                  <div className=" my-1">
                    <h2 className="text-2xl font-bold mb-5">
                      Most Read Newspapers of {countryData.name}
                    </h2>
                  </div>
                  {newspaperData && (
                    <div className=" items-center">
                      {newspaperData.map((newspaper, index) => (
                        <div className=" ">
                          <div key={index} className="flex space-x-1 justify-between bg-opacity-40 bg-white border backdrop-blur-[3px] border-gray-300 shadow-md rounded-lg my-2 p-2" onClick={() => handleClick(newspaper)}>
                            <div className="h-full w-[40%] mr-2 overflow-hidden flex">
                              <div className="self-center w-full">
                                {newspaper.logo && (
                                  <img
                                    src={newspaper.logo}
                                    alt="Logo"
                                    className="h-7"
                                  />
                                )}
                              </div>
                            </div>

                            <div className="flex justify-center w-[20%]">
                              <p className="self-center text-lg mr-1">
                                {Math.floor((newspaper.negative/(newspaper.positive+newspaper.neutral+newspaper.negative))*100)+"%"}
                              </p>
                              <div className="self-center">
                              <MicroPieChart
                                hoveredPositive={Math.floor((newspaper.negative/(newspaper.positive+newspaper.neutral+newspaper.negative))*100)}
                                hoveredNegative={100 - Math.floor((newspaper.negative/(newspaper.positive+newspaper.neutral+newspaper.negative))*100)}
                                fillType="negative"
                              />
                              </div>
                            </div>

                            <div className="flex  justify-center w-[20%]">
                              <p className="self-center text-lg mr-1">
                                {Math.floor((newspaper.neutral/(newspaper.positive+newspaper.neutral+newspaper.negative))*100)+"%"}
                              </p>
                              <div className="self-center">
                              <MicroPieChart
                                hoveredPositive={Math.floor((newspaper.neutral/(newspaper.positive+newspaper.neutral+newspaper.negative))*100)}
                                hoveredNegative={100 - Math.floor((newspaper.neutral/(newspaper.positive+newspaper.neutral+newspaper.negative))*100)}
                                fillType="neutral"
                              />
                              </div>
                            </div>

                            <div className="flex justify-center w-[20%]">
                              <div className="self-center">
                                <p className=" text-lg mr-1">
                                  {Math.floor((newspaper.positive/(newspaper.positive+newspaper.neutral+newspaper.negative))*100)+"%"}
                                </p>
                              </div>
                              <div className="self-center">
                              <MicroPieChart
                                hoveredPositive={Math.floor((newspaper.positive/(newspaper.positive+newspaper.neutral+newspaper.negative))*100)}
                                hoveredNegative={100 - Math.floor((newspaper.positive/(newspaper.positive+newspaper.neutral+newspaper.negative))*100)}
                                fillType="positive"
                              />
                              </div>
                            </div>
                          </div>
                          <hr className="border-t-2 border-black w-full" />
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
  );
}

export default CountryDetails;
