import React, { useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { BarLoader } from 'react-spinners';

import illustration from "../assets/maintenaceImage.png";
import logo2 from "../assets/Beta.svg";
import backgroundImage from "../assets/backgroundMain.jpg";
import twitter from "../assets/twitter.svg";
import youtube from "../assets/youtube.svg";
import linkedin from "../assets/linkedin.svg";



function MaintenancePage() {

    const isMobile = useMediaQuery({ maxWidth: 767 }); // Define the mobile breakpoint
    const isLaptop = useMediaQuery({ minWidth: 780 });

    const [colorBar, setColorBar] = useState("#ee334d");

  const containerStyle = {
    margin: "0 0 0 0",
    padding: "0 0 0 0",
    backgroundImage: `url(${backgroundImage})`, // Set the background image
    backgroundSize: "cover", // Adjust background sizing
    backgroundRepeat: "no-repeat", // Prevent repeating of background image
    backgroundColor: "#f2f2f2",
    // Add other styles as needed
  };

  var dateActuelle = new Date();
  var minute = dateActuelle.getMinutes();
  var percent = (minute/10)*100;

  function getRandomColor() {
    console.log("TCV&NB");
    const colors = ["#ee334d", "#fac80c", "#74bf50"];
    const randomIndex = Math.floor(Math.random() * colors.length);
    setColorBar(colors[randomIndex]);
  }

  setTimeout(() => {
    getRandomColor();
  }, 1900);

  return (
    <div>
        {isLaptop && (
            <div id="" style={containerStyle} className="w-full h-screen font-custom">   
                <div className='h-full w-full p-10'> 
                    <div className='flex h-full space-x-[2%]'>
                        <div className='w-[35%] space-y-[5%]'>
                            <div className='bg-opacity-40 border backdrop-blur-[3px] border-gray-300 items-center shadow-lg rounded-xl flex h-[17%]'>
                                <div className='flex justify-center h-full w-full'>
                                    <img src={logo2} alt="logo" className="self-center h-1/2 " />
                                </div>
                            </div>  
                            <div className='bg-opacity-40 border backdrop-blur-[3px] border-gray-300 items-center shadow-lg rounded-xl flex h-[80%]'>
                                <img src={illustration} alt="logo" className=" " />
                            </div>
                        </div>
                        <div className='w-[63%] space-y-[3%]'>
                            <div className='h-[57%] bg-opacity-40 border backdrop-blur-[3px] border-gray-300 items-center shadow-lg rounded-xl'>
                                <div className='h-full w-full flex'>
                                    <div className='self-center w-full space-y-6'>
                                        <h2 className='text-5xl font-bold  flex justify-center'>Sorry, we are updating our site</h2>
                                        <div className='flex justify-center'>
                                            <h1 className='text-xl font-bold text-gray-400'>
                                                Come back in few minutes for more{" "}
                                                <span className="text-custom-green">stats</span>
                                                , more{" "}
                                                <span className="text-custom-yellow2">data</span>
                                                , more{" "}
                                                <span className="text-custom-red">articles</span>
                                                ...
                                            </h1>
                                        </div>
                                        <div className="spinner-container w-full flex justify-center">
                                            <BarLoader color={colorBar} width={400} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className='bg-opacity-40 border backdrop-blur-[3px] border-gray-300 items-center shadow-lg rounded-xl h-[40%]'>
                            <div className='h-full w-full flex'>
                                    <div className='self-center w-full space-y-6'>
                                        <h2 className='text-3xl font-bold text-black flex justify-center'>Follow us on social media</h2>
                                        <div className='flex justify-center space-x-5 w-full'>
                                            <div className='h-full w-[25%]'>
                                                <a href="https://twitter.com" className="flex space-x-3 justify-center rounded-lg p-2 border-2 border hover:border-black ">
                                                    <img src={twitter} alt="global " className=" w-10" />
                                                    <p className='self-center font-bold'>@kutniti</p>
                                                </a>
                                            </div>
                                            <div className='h-full w-[25%]'>
                                                <a href="https://www.linkedin.com/company/kutniti-foundation/" className="flex space-x-3 justify-center p-2 border-2 border hover:border-black rounded-lg">
                                                    <img src={linkedin} alt="global " className="w-10" />
                                                    <p className='self-center font-bold'>Kutniti Foundation</p>
                                                </a>                    
                                            </div>
                                            <div className='h-full w-[25%]'>
                                                <a href="https://www.youtube.com/channel/UCxTzoqfRsg8F8pU88QC9whg" className="flex space-x-3 justify-center p-2 border-2 border hover:border-black rounded-lg">
                                                    <img src={youtube} alt="global " className="w-10" />
                                                    <p className='self-center font-bold'>Kutniti Foundation</p>
                                                </a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
        {isMobile && (
            <div id="" style={containerStyle} className="w-full h-screen font-custom">
                <div className='h-[90%] w-full p-5 space-y-[3%]'>
                    <div className='bg-opacity-40 border backdrop-blur-[3px] border-gray-300 items-center shadow-lg rounded-xl w-full h-[14%]'>
                        <div className='flex justify-center h-full w-full'>
                            <img src={logo2} alt="logo" className="self-center h-1/2 " />
                        </div>
                    </div>
                    <div className='bg-opacity-40 border backdrop-blur-[3px] border-gray-300 items-center shadow-lg rounded-xl w-full h-[55%]'>
                        <div className='h-full'>
                            <div className='flex justify-center h-[70%]'>
                            <img src={illustration} alt="logo" className=" " />
                            </div>
                            <div className='w-full flex h-[30%]'>
                                <div className='w-full space-y-1'>
                                    <h2 className='text-xl font-bold  flex justify-center'>Sorry, we are updating our site</h2>
                                    <div className='flex justify-center'>
                                        <h1 className='text-xs font-bold text-gray-400 text-center'>
                                            Come back in few minutes for more{" "}
                                            <span className="text-custom-green">stats</span>
                                            , more{" "}
                                            <span className="text-custom-yellow2">data</span>
                                            , more{" "}
                                            <span className="text-custom-red">articles</span>
                                            ...
                                        </h1>
                                    </div>
                                    <div className="spinner-container w-full pt-2 flex justify-center">
                                        <BarLoader color={colorBar} width={200} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='bg-opacity-40 border backdrop-blur-[3px] border-gray-300 items-center shadow-lg rounded-xl w-full'>
                        <div className='h-full w-full flex'>
                            <div className='self-center w-full py-3 space-y-3'>
                                <h2 className='text-xl font-bold text-black flex justify-center'>Follow us on social media</h2>
                                <div className="flex justify-center w-full">
                                <div className='space-y-2 w-3/4'>
                                    <div className=''>
                                        <a href="https://twitter.com" className="flex space-x-2 justify-center rounded-lg p-2 border-2 border hover:border-black ">
                                            <img src={twitter} alt="global " className=" w-6" />
                                            <p className='self-center font-bold text-xs'>@kutniti</p>
                                        </a>
                                    </div>
                                    <div className=''>
                                        <a href="https://linkedin.com" className="flex space-x-2 justify-center p-2 border-2 border hover:border-black rounded-lg">
                                            <img src={linkedin} alt="global " className="w-6" />
                                            <p className='self-center font-bold text-xs'>Kutniti Foundation</p>
                                        </a>                    
                                    </div>
                                    <div className=''>
                                        <a href="https://youtube.com" className="flex space-x-2 justify-center p-2 border-2 border hover:border-black rounded-lg">
                                            <img src={youtube} alt="global " className="w-6" />
                                            <p className='self-center font-bold text-xs'>Kutniti Foundation</p>
                                        </a>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )}
    </div>
  );
}

export default MaintenancePage;
